import Phaser from "phaser";
import { Village } from "./scenes/Village";
import { Login } from "./scenes/Login";
import { Field } from "./scenes/Field";
import { Field1 } from "./scenes/Field1";
import { Field2 } from "./scenes/Field2";
import { ChatScene } from "./scenes/ChatScene";
import { PanelScene } from "./scenes/PanelScene";
import { House } from "./scenes/House";
import { Field3 } from "./scenes/Field3";
import RexUIPlugin from "phaser3-rex-plugins/templates/ui/ui-plugin.js";
import { VillageMain } from "./scenes/VillageMain";

const config: Phaser.Types.Core.GameConfig = {
  type: Phaser.AUTO,
  fps: {
    target: 60,
    forceSetTimeOut: true,
  },
  scale: {
    mode: Phaser.Scale.RESIZE,
    autoCenter: Phaser.Scale.CENTER_BOTH,
  },
  physics: {
    default: "arcade",
  },
  plugins: {
    scene: [
      {
        key: "rexUI",
        plugin: RexUIPlugin,
        mapping: "rexUI",
      },
    ],
  },
  pixelArt: true,
  scene: [
    Login,
    Village,
    VillageMain,
    Field,
    Field1,
    Field2,
    Field3,
    House,
    ChatScene,
    PanelScene,
  ],
};

new Phaser.Game(config);
