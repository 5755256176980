import Phaser from "phaser";

export class AssetLoader {
  static loadCommonAssets(scene: Phaser.Scene) {
    // Common background and tileset assets
    scene.load.image("forest-bg", "assets/landscape.png");
    scene.load.image("tileset", "assets/Map/VillageMap/_PNG/MainLevBuild.png");
    scene.load.image(
      "buildings",
      "assets/Map/VillageBuildings/_PNG/VP2_Main.png"
    );
    scene.load.image(
      "decoratives",
      "assets/Map/VillageMap/_PNG/Decoratives.png"
    );
    scene.load.image(
      "interior",
      "assets/Map/VillageBuildings/_PNG/VP2_Interriors.png"
    );

    // Character animations
    scene.load.spritesheet(
      "character-idle",
      "assets/Character/Idle/Idle-Sheet.png",
      {
        frameWidth: 64,
        frameHeight: 64,
      }
    );
    scene.load.spritesheet(
      "character-run",
      "assets/Character/Run/Run-Sheet.png",
      {
        frameWidth: 80,
        frameHeight: 80,
      }
    );
    scene.load.spritesheet(
      "character-jump",
      "assets/Character/Jump-All/Jump-All-Sheet.png",
      {
        frameWidth: 64,
        frameHeight: 64,
      }
    );
    scene.load.spritesheet(
      "character-attack",
      "assets/Character/Attack-01/Attack-01-Sheet.png",
      {
        frameWidth: 96,
        frameHeight: 80,
      }
    );
    scene.load.spritesheet(
      "character-dead",
      "assets/Character/Dead/Dead-Sheet.png",
      {
        frameWidth: 64,
        frameHeight: 64,
      }
    );

    // Level up effect
    scene.load.spritesheet("level-up-effect", "assets/LevelUp.png", {
      frameWidth: 128,
      frameHeight: 128,
    });

    // Loot assets
    scene.load.spritesheet("coin", "assets/Loot/coin.png", {
      frameWidth: 8,
      frameHeight: 8,
    });

    // Add this to the loadCommonAssets method
    scene.load.spritesheet(
      "loot-items",
      "assets/Loot/Freebies_Full_Icons.png",
      {
        frameWidth: 32,
        frameHeight: 32,
      }
    );

    // Sounds
    scene.load.audio("collectcoin", "assets/Sounds/collectcoin.mp3");
    scene.load.audio("swordslash", "assets/Sounds/swordslash.mp3");
    scene.load.audio("enemyhit", "assets/Sounds/enemyhit.mp3");
    scene.load.audio("portalenter", "assets/Sounds/portalenter.mp3");
    scene.load.audio("jump", "assets/Sounds/jump.mp3");
  }

  static loadBoarAssets(scene: Phaser.Scene) {
    scene.load.spritesheet("boar-idle", "assets/Mob/Boar/Idle/Idle-Sheet.png", {
      frameWidth: 48,
      frameHeight: 32,
    });
    scene.load.spritesheet("boar-run", "assets/Mob/Boar/Run/Run-Sheet.png", {
      frameWidth: 48,
      frameHeight: 32,
    });
    scene.load.spritesheet(
      "boar-walk",
      "assets/Mob/Boar/Walk/Walk-Base-Sheet.png",
      {
        frameWidth: 48,
        frameHeight: 32,
      }
    );
    scene.load.spritesheet(
      "boar-vanish",
      "assets/Mob/Boar/Hit-Vanish/Hit-Sheet.png",
      {
        frameWidth: 48,
        frameHeight: 32,
      }
    );
  }

  static loadWhiteBoarAssets(scene: Phaser.Scene) {
    scene.load.spritesheet(
      "white-boar-idle",
      "assets/Mob/Boar/Idle/Idle-Sheet-White.png",
      {
        frameWidth: 48,
        frameHeight: 32,
      }
    );
    scene.load.spritesheet(
      "white-boar-walk",
      "assets/Mob/Boar/Walk/Walk-Base-Sheet-White.png",
      {
        frameWidth: 48,
        frameHeight: 32,
      }
    );
    scene.load.spritesheet(
      "white-boar-vanish",
      "assets/Mob/Boar/Hit-Vanish/Hit-Sheet-White.png",
      {
        frameWidth: 48,
        frameHeight: 32,
      }
    );
    scene.load.spritesheet(
      "white-boar-run",
      "assets/Mob/Boar/Run/Run-Sheet-White.png",
      {
        frameWidth: 48,
        frameHeight: 32,
      }
    );
  }

  static loadBlackBoarAssets(scene: Phaser.Scene) {
    scene.load.spritesheet(
      "black-boar-idle",
      "assets/Mob/Boar/Idle/Idle-Sheet-Black.png",
      {
        frameWidth: 48,
        frameHeight: 32,
      }
    );
    scene.load.spritesheet(
      "black-boar-walk",
      "assets/Mob/Boar/Walk/Walk-Base-Sheet-Black.png",
      {
        frameWidth: 48,
        frameHeight: 32,
      }
    );
    scene.load.spritesheet(
      "black-boar-vanish",
      "assets/Mob/Boar/Hit-Vanish/Hit-Sheet-Black.png",
      {
        frameWidth: 48,
        frameHeight: 32,
      }
    );
    scene.load.spritesheet(
      "black-boar-run",
      "assets/Mob/Boar/Run/Run-Sheet-Black.png",
      {
        frameWidth: 48,
        frameHeight: 32,
      }
    );
  }

  static loadBeeAssets(scene: Phaser.Scene) {
    scene.load.spritesheet(
      "bee-walk",
      "assets/Mob/Small Bee/Walk/Walk-Sheet.png",
      {
        frameWidth: 64,
        frameHeight: 64,
      }
    );
    scene.load.spritesheet(
      "bee-hit",
      "assets/Mob/Small Bee/Hit/Hit-Sheet.png",
      {
        frameWidth: 64,
        frameHeight: 64,
      }
    );
  }

  static loadSnailAssets(scene: Phaser.Scene) {
    scene.load.spritesheet("Dead", "assets/Mob/Snail/Dead.png", {
      frameWidth: 48,
      frameHeight: 32,
    });
    scene.load.spritesheet("Hide", "assets/Mob/Snail/Hide.png", {
      frameWidth: 48,
      frameHeight: 32,
    });
    scene.load.spritesheet("Idle", "assets/Mob/Snail/Idle.png", {
      frameWidth: 48,
      frameHeight: 32,
    });
  }

  static loadBrownWolfAssets(scene: Phaser.Scene) {
    scene.load.spritesheet(
      "Canine_Brown_Hit",
      "assets/Mob/Canines/Canine_Brown_Hit.png",
      {
        frameWidth: 48,
        frameHeight: 32,
      }
    );
    scene.load.spritesheet(
      "Canine_Brown_Idle",
      "assets/Mob/Canines/Canine_Brown_Idle.png",
      {
        frameWidth: 48,
        frameHeight: 32,
      }
    );
    scene.load.spritesheet(
      "Canine_Brown_Run",
      "assets/Mob/Canines/Canine_Brown_Run.png",
      {
        frameWidth: 48,
        frameHeight: 32,
      }
    );
    scene.load.spritesheet(
      "Canine_Brown_Death",
      "assets/Mob/Canines/Canine_Brown_Death.png",
      {
        frameWidth: 48,
        frameHeight: 32,
      }
    );
  }

  static loadBlackWolfAssets(scene: Phaser.Scene) {
    scene.load.spritesheet(
      "Canine_Black_Hit",
      "assets/Mob/Canines/Canine_Black_Hit.png",
      {
        frameWidth: 48,
        frameHeight: 32,
      }
    );
    scene.load.spritesheet(
      "Canine_Black_Idle",
      "assets/Mob/Canines/Canine_Black_Idle.png",
      {
        frameWidth: 48,
        frameHeight: 32,
      }
    );
    scene.load.spritesheet(
      "Canine_Black_Run",
      "assets/Mob/Canines/Canine_Black_Run.png",
      {
        frameWidth: 48,
        frameHeight: 32,
      }
    );
    scene.load.spritesheet(
      "Canine_Black_Death",
      "assets/Mob/Canines/Canine_Black_Death.png",
      {
        frameWidth: 48,
        frameHeight: 32,
      }
    );
  }

  static loadGrayWolfAssets(scene: Phaser.Scene) {
    scene.load.spritesheet(
      "Canine_Gray_Hit",
      "assets/Mob/Canines/Canine_Gray_Hit.png",
      {
        frameWidth: 48,
        frameHeight: 32,
      }
    );
    scene.load.spritesheet(
      "Canine_Gray_Idle",
      "assets/Mob/Canines/Canine_Gray_Idle.png",
      {
        frameWidth: 48,
        frameHeight: 32,
      }
    );
    scene.load.spritesheet(
      "Canine_Gray_Run",
      "assets/Mob/Canines/Canine_Gray_Run.png",
      {
        frameWidth: 48,
        frameHeight: 32,
      }
    );
    scene.load.spritesheet(
      "Canine_Gray_Death",
      "assets/Mob/Canines/Canine_Gray_Death.png",
      {
        frameWidth: 48,
        frameHeight: 32,
      }
    );
  }

  static loadWhiteWolfAssets(scene: Phaser.Scene) {
    scene.load.spritesheet(
      "Canine_White_Hit",
      "assets/Mob/Canines/Canine_White_Hit.png",
      {
        frameWidth: 48,
        frameHeight: 32,
      }
    );
    scene.load.spritesheet(
      "Canine_White_Idle",
      "assets/Mob/Canines/Canine_White_Idle.png",
      {
        frameWidth: 48,
        frameHeight: 32,
      }
    );
    scene.load.spritesheet(
      "Canine_White_Run",
      "assets/Mob/Canines/Canine_White_Run.png",
      {
        frameWidth: 48,
        frameHeight: 32,
      }
    );
    scene.load.spritesheet(
      "Canine_White_Death",
      "assets/Mob/Canines/Canine_White_Death.png",
      {
        frameWidth: 48,
        frameHeight: 32,
      }
    );
  }

  static loadVillageAssets(scene: Phaser.Scene) {
    this.loadCommonAssets(scene);
    this.loadSnailAssets(scene);
    scene.load.tilemapTiledJSON("village-map", "assets/VillageMap.tmj");
  }

  static loadFieldAssets(scene: Phaser.Scene) {
    this.loadCommonAssets(scene);
    this.loadBoarAssets(scene);
    this.loadWhiteBoarAssets(scene);
    scene.load.tilemapTiledJSON("field-map", "assets/FieldMap.tmj");
  }

  static loadField1Assets(scene: Phaser.Scene) {
    this.loadCommonAssets(scene);
    this.loadBoarAssets(scene);
    this.loadBlackBoarAssets(scene);
    scene.load.tilemapTiledJSON("field1-map", "assets/FieldMap1.tmj");
  }

  static loadField2Assets(scene: Phaser.Scene) {
    this.loadCommonAssets(scene);
    this.loadBoarAssets(scene);
    this.loadBeeAssets(scene);
    scene.load.tilemapTiledJSON("field2-map", "assets/FieldMap2.tmj");
  }

  static loadField3Assets(scene: Phaser.Scene) {
    this.loadCommonAssets(scene);
    this.loadBlackWolfAssets(scene);
    this.loadBrownWolfAssets(scene);
    scene.load.tilemapTiledJSON("field3-map", "assets/FieldMap3.tmj");
  }
  static loadHouseAssets(scene: Phaser.Scene) {
    this.loadCommonAssets(scene);
    scene.load.tilemapTiledJSON("house-map", "assets/HouseMap.tmj");
  }
  static loadVillageMainAssets(scene: Phaser.Scene) {
    this.loadCommonAssets(scene);
    scene.load.tilemapTiledJSON("villagemain-map", "assets/VillageMain.tmj");
  }

  static loadMusicAssets(scene: Phaser.Scene) {
    scene.load.audio("village-music", "assets/Music/forestSyncopika.mp3");
    scene.load.audio("field-music", "assets/Music/forestSyncopika.mp3");
    scene.load.audio("field1-music", "assets/Music/forestSyncopika.mp3");
    scene.load.audio("field2-music", "assets/Music/forestSyncopika.mp3");
    scene.load.audio("field3-music", "assets/Music/forestSyncopika.mp3");
  }

  static getLootFrame(lootName: string): number {
    // Map of loot types to frame numbers in the loot-items spritesheet
    const lootFrameMap: { [key: string]: number } = {
      smallCoin: 0,
      mediumCoin: 0,
      largeCoin: 0,
      sword: 13,
      whiteFeather: 216,
      whiteQuill: 217,
      greyFeather: 218,
      greyQuill: 219,
      blackFeather: 220,
      blackQuill: 221,
      brownFeather: 222,
      brownQuill: 223,
      redFeather: 224,
      redQuill: 225,
      violetFeather: 226,
      violetQuill: 227,
      purpleFeather: 228,
      purpleQuill: 229,
      blueFeather: 230,
      blueQuill: 231,
      tealFeather: 232,
      tealQuill: 233,
      greenFeather: 234,
      greenQuill: 235,
      yellowFeather: 236,
      yellowQuill: 237,
      cherry: 91,
      blackCurrant: 92,
      redCurrant: 93,
      cucumber: 94,
      sugarApple: 95,
      date: 96,
      dragonFruit: 97,
      breadFruit: 98,
      fig: 99,
      rubyGrape: 100,
      muscatGrape: 101,
      grapeFruit: 102,
      honeyDew: 103,
      kiwi: 104,
      yellowMelon: 105,
      redApple: 106,
      tangerine: 107,
      greenPear: 108,
      nectarine: 109,
      passionFruit: 110,
      apricot: 111,
      strawberry: 112,
      watermelon: 113,
      cantaloupe: 114,
    };

    return lootFrameMap[lootName] || 0; // Default to first frame if not found
  }
}
