import Phaser from "phaser";
import { Room } from "colyseus.js";

export class ChatScene extends Phaser.Scene {
  private room: Room;
  private mainPanel: any;
  private inputBox: any;
  private chatFocused: boolean = false;
  private keydownHandler: (e: KeyboardEvent) => void;
  private keyupHandler: (e: KeyboardEvent) => void;

  constructor() {
    super({ key: "chat" });
  }

  create() {
    // Get the Colyseus room from the registry
    this.room = this.registry.get("room");
    if (!this.room) {
      console.error("No room found in registry");
      return;
    }

    this.game.events.on("portal-collision", () => {
      this.room = this.registry.get("room");
      this.room.onMessage("chat", (message) => {
        const msg = {
          senderName: message.username,
          message: message.text,
        };
        this.mainPanel.appendMessage(msg, { userID: currentUsername });
      });
    });

    const currentUsername = this.registry.get("playerData").username;

    const config = {
      x: window.innerWidth * 0.2,
      y: window.innerHeight - 50,
      width: window.innerWidth * 0.4,
      height: 100,
      color: {
        background: 0x0e376f, // Dark blue background
        alpha: {
          background: 0.7, // Added transparency for main background
          inputBackground: 0.6, // Input area transparency
          track: 0.6, // Scrollbar track transparency
        },
        track: 0x3a6ba5, // Scrollbar track
        thumb: 0xbfcdbb, // Scrollbar thumb
        inputBackground: 0x685784, // Input area background
        inputBox: 0x182456, // Input box background
        messageBackground: 0x696969, // Message bubble stroke
      },
      userName: currentUsername,
    };

    // Create the RexUI chat panel
    const chatUI = CreateMainPanel(this, config);
    this.mainPanel = chatUI.panel;
    this.inputBox = chatUI.inputBox;
    this.mainPanel.layout();
    this.mainPanel.setScrollFactor(0); // Keep chat fixed on screen
    this.mainPanel.on("send-message", (message) => {
      this.room.send("chat", { text: message });
    });
    this.room.onMessage("chat", (message) => {
      const msg = {
        senderName: message.username,
        message: message.text,
      };
      this.mainPanel.appendMessage(msg, { userID: currentUsername });
    });
    this.setupKeyEventHandlers();
    this.scale.on("resize", this.resizeChat, this);
  }

  private setupKeyEventHandlers() {
    const keysToPrevent = [
      "W",
      "A",
      "S",
      "D",
      "SPACE",
      "UP",
      "DOWN",
      "LEFT",
      "RIGHT",
      "ENTER",
      "Z",
    ];

    const keyMap = {};
    keysToPrevent.forEach((key) => {
      keyMap[Phaser.Input.Keyboard.KeyCodes[key]] = true;
    });

    this.keydownHandler = (e: KeyboardEvent) => {
      if (this.chatFocused) {
        if (e.keyCode === 13) {
          // Enter key
          const message = this.inputBox.text;
          if (message !== "") {
            this.mainPanel.emit("send-message", message);
            this.inputBox.text = "";
          }
          e.stopPropagation();
          e.preventDefault();
        } else if (e.keyCode === 32) {
          // Space key - handle it normally without adding period
          // Let the default behavior happen, just prevent game controls
          e.stopPropagation();
          // Don't prevent default so normal space can be entered
        } else if (keyMap[e.keyCode]) {
          e.stopPropagation();
        }
      }
    };

    this.keyupHandler = (e: KeyboardEvent) => {
      if (this.chatFocused && keyMap[e.keyCode]) {
        e.stopPropagation();
      }
    };

    window.addEventListener("keydown", this.keydownHandler, true);
    window.addEventListener("keyup", this.keyupHandler, true);
  }

  resizeChat() {
    const chatX = window.innerWidth * 0.2;
    const chatY = window.innerHeight - 50;
    const chatWidth = window.innerWidth * 0.4;
    const chatHeight = 100;
    this.mainPanel.x = chatX;
    this.mainPanel.y = chatY;
    this.mainPanel.setMinSize(chatWidth, chatHeight); // Set new dimensions
    this.mainPanel.layout();
  }

  shutdown() {
    // Clean up the RexUI panel when the scene shuts down
    if (this.mainPanel) {
      this.mainPanel.destroy();
      this.mainPanel = null;
    }

    // Clean up key event listeners
    if (this.keydownHandler) {
      window.removeEventListener("keydown", this.keydownHandler, true);
    }

    if (this.keyupHandler) {
      window.removeEventListener("keyup", this.keyupHandler, true);
    }
  }
}

// RexUI component creation functions (adapted for Colyseus and your game)
const CreateMainPanel = function (scene, config) {
  const mainPanel = scene.rexUI.add.sizer({
    x: config.x,
    y: config.y,
    width: config.width,
    height: config.height,
    orientation: "y",
  });
  const upperPanel = scene.rexUI.add.sizer({ orientation: "x" });
  const background = scene.rexUI.add.roundRectangle({
    radius: 5,
    color: config.color.background,
    alpha: config.color.alpha.background, // Apply transparency
  });
  const messageBox = CreateMessageBox(mainPanel, config);
  const inputPanel = CreateInputPanel(scene, mainPanel, config);

  upperPanel.add(messageBox, 1, "center", 0, true);
  mainPanel
    .addBackground(background)
    .add(
      upperPanel,
      1,
      "center",
      { top: 5, bottom: 5, left: 3, right: 3 },
      true
    )
    .add(inputPanel, 0, "center", 0, true);

  return {
    panel: mainPanel,
    inputBox: inputPanel.getElement("text"),
  };
};

const CreateMessageBox = function (parent, config) {
  const scene = parent.scene;
  const messageBox = scene.rexUI.add.gridTable({
    table: {
      cellHeight: 15,
      mask: { padding: 1 },
      reuseCellContainer: true,
    },
    slider: {
      track: scene.rexUI.add.roundRectangle({
        width: 8,
        height: 10,
        radius: 3,
        color: config.color.track,
        alpha: config.color.alpha.track * 0.8, // Slightly more transparent
      }),
      thumb: scene.rexUI.add.roundRectangle({
        width: 6, // Make thumb slightly narrower than track
        radius: 3,
        color: config.color.thumb,
      }),
    },
    createCellContainerCallback(cell, cellContainer) {
      const { scene, width, item, items, index } = cell;
      if (!cellContainer) {
        cellContainer = scene.rexUI.add
          .label({
            orientation: "x",
            background: scene.rexUI.add.roundRectangle({
              strokeColor: 0x000000,
              strokeWidth: 0, // Remove border
              radius: 0, // Remove rounded corners
              fillStyle: 0x000000, // Black fill for placeholder
              alpha: 0, // Fully transparent - no message boxes
            }),
            text: scene.rexUI.add.BBCodeText(0, 0, "", {
              wrap: { mode: "word" },
            }),
            space: { left: 5, right: 5, top: 3, bottom: 3 }, // Reduced padding
          })
          .setOrigin(0);
      }

      // Always show username with every message
      const isOwnMessage = !item.isLeft;

      // Format message with username, using color for own messages
      let displayText = isOwnMessage
        ? `[b][color=#AAFFAA]${item.name}:[/color][/b] ${item.content}`
        : `[b]${item.name}:[/b] ${item.content}`;

      // Get the message background (now invisible)
      const background = cellContainer.getElement("background");
      background.setAlpha(0); // Ensure it's invisible

      cellContainer
        .getElement("text")
        .setWrapWidth(width - 15) // Slightly wider text area
        .setText(displayText);

      // Always align to left
      cellContainer.setRTL(false);
      cell.setCellContainerAlign("left");

      // Add consistent padding between messages
      cellContainer.setInnerPadding("top", 3); // Reduced padding

      cellContainer.setDirty(true).layout().setDirty(false);
      cell.height = cellContainer.height;

      return cellContainer;
    },
    name: "messageBox",
  });

  parent.appendMessage = function (message, userInfo) {
    const myUserID = userInfo.userID;
    const messages = messageBox.items;
    messages.push({
      name: message.senderName,
      content: message.message,
      isLeft: myUserID !== message.senderName,
    });
    messageBox.setItems(messages).scrollToBottom();
  };
  return messageBox;
};

const CreateInputPanel = function (scene, parent, config) {
  const background = scene.rexUI.add.roundRectangle({
    radius: { bl: 5, br: 5 },
    color: config.color.inputBackground,
    alpha: config.color.alpha.inputBackground, // Apply transparency
  });
  const inputBox = scene.rexUI.add.canvasInput({
    width: 100,
    height: 20,
    background: { color: `#${config.color.inputBox.toString(16)}` },
    style: {
      fontSize: 16,
      backgroundBottomY: 4,
      backgroundHeight: 20,
      "cursor.color": "black",
      "cursor.backgroundColor": "white",
    },
    text: "",
    selectAll: true,
  });
  const sendBtn = scene.rexUI.add.BBCodeText(0, 0, "Send", {});

  const inputPanel = scene.rexUI.add.label({
    height: 30,
    background: background,
    text: inputBox,
    expandTextWidth: true,
    action: sendBtn,
    space: { left: 8, right: 8, top: 0, bottom: 0, text: 5 },
  });

  sendBtn.setInteractive().on("pointerdown", () => {
    if (inputBox.text !== "") {
      parent.emit("send-message", inputBox.text);
      inputBox.text = "";
    }
    inputBox.close();
  });

  // Enhance the input box event handlers to properly handle focus and keyboard events
  inputBox
    .on("open", () => {
      scene.chatFocused = true; // Make sure to update the scene's state
    })
    .on("close", () => {
      scene.chatFocused = false; // Make sure to update the scene's state
    });

  return inputPanel;
};
