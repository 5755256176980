export default {
    v: 0,
    vertical: 0,
    y: 0,

    h: 1,
    horizontal: 1,
    x: 1,

    xy: 2,
    vh: 2,
};