import AddEffectProperties from './behaviors/effectproperties/AddEffectProperties.js';
export default AddEffectProperties;

import {
    AddBarrelProperties,
    AddBlackWhiteProperties,
    AddBloomProperties,
    AddBlurProperties,
    AddBokehProperties,
    AddBrightnessProperties,
    AddBrownProperties,
    AddCircleProperties,
    AddContrastProperties,
    AddDesaturateProperties,
    AddDesaturateLuminanceProperties,
    AddDisplacementProperties,
    AddGlowProperties,
    AddGradientProperties,
    AddGrayscaleProperties,
    AddHueProperties,
    AddKodachromeProperties,
    AddLSDProperties,
    AddNegativeProperties,
    AddPixelateProperties,
    AddPolaroidProperties,
    AddRevealProperties,
    AddSaturateProperties,
    AddSepiaProperties,
    AddShadowProperties,
    AddShiftToBGRProperties,
    AddShineProperties,
    AddTechnicolorProperties,
    AddTiltShiftProperties,
    AddVignetteProperties,
    AddVintagePinholeProperties,
    AddWipeProperties
} from './behaviors/effectproperties/EffectProperties.js';

export {
    AddBarrelProperties,
    AddBlackWhiteProperties,
    AddBloomProperties,
    AddBlurProperties,
    AddBokehProperties,
    AddBrightnessProperties,
    AddBrownProperties,
    AddCircleProperties,
    AddContrastProperties,
    AddDesaturateProperties,
    AddDesaturateLuminanceProperties,
    AddDisplacementProperties,
    AddGlowProperties,
    AddGradientProperties,
    AddGrayscaleProperties,
    AddHueProperties,
    AddKodachromeProperties,
    AddLSDProperties,
    AddNegativeProperties,
    AddPixelateProperties,
    AddPolaroidProperties,
    AddRevealProperties,
    AddSaturateProperties,
    AddSepiaProperties,
    AddShadowProperties,
    AddShiftToBGRProperties,
    AddShineProperties,
    AddTechnicolorProperties,
    AddTiltShiftProperties,
    AddVignetteProperties,
    AddVintagePinholeProperties,
    AddWipeProperties
}