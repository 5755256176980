export default {
    setConfirmButtonIndex(index) {
        this.confirmButtonIndex = index;
        return this;
    },

    setCancelButtonIndex(index) {
        this.cancelButtonIndex = index;
        return this;
    },
}