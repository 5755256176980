import { BaseGameScene } from "./BaseGameScene";
import { AssetLoader } from "../utils/AssetLoader";

export class Field1 extends BaseGameScene {
  constructor() {
    super({ key: "field1" });
  }

  preload() {
    AssetLoader.loadField1Assets(this);
    this.load.tilemapTiledJSON("field1-map", "assets/FieldMap1.tmj");
    this.loadSounds();
    this.load.audio("field1-music", "assets/Music/woodsAxtonCrolley.wav");
  }

  async create() {
    this.room = this.registry.get("room");
    this.client = this.registry.get("client");

    if (this.room) {
      this.registry.set("room", this.room);

      // Initialize player data with current inventory
      const username = this.registry.get("playerData")?.username;
      if (username) {
        const player = this.room.state.spawnedPlayers.find(
          (p) => p.username === username
        );
        if (player && player.inventory) {
          // Convert MapSchema inventory to array format
          const inventoryArray: any[] = [];
          player.inventory.forEach((loot: any, key: string) => {
            inventoryArray.push({
              loot: loot,
              quantity: player.inventoryQuantities.get(key) || 0,
            });
          });

          // Update player data in registry
          const currentPlayerData = this.registry.get("playerData");
          if (currentPlayerData) {
            const updatedPlayerData = {
              ...currentPlayerData,
              inventory: inventoryArray,
            };
            this.registry.set("playerData", updatedPlayerData);

            // Emit event for inventory panel to update
            this.game.events.emit("inventory-updated");
          }
        }
      }

      if (!this.scene.isActive("chat")) this.scene.launch("chat");
      if (!this.scene.isActive("panel")) this.scene.launch("panel");
    }

    this.initSounds();
    this.playBackgroundMusic("field1-music");
    super.createHealthBar();

    const map = this.setupMap("field1-map", [
      ["MainLevBuild", "tileset"],
      ["Decoratives", "decoratives"],
    ]);
    this.setupPhysicsAndCamera(map.widthInPixels, map.heightInPixels);
    this.setupInputKeys();
    this.setupPortalCollisions();
    this.createPlayerAnimations();
    this.createLootAnimations();

    // Scene-specific monster animations
    this.anims.create({
      key: "boar-idle",
      frames: this.anims.generateFrameNumbers("boar-idle", {
        start: 0,
        end: 3,
      }),
      frameRate: 8,
      repeat: -1,
    });
    this.anims.create({
      key: "boar-run",
      frames: this.anims.generateFrameNumbers("boar-run", { start: 0, end: 5 }),
      frameRate: 10,
      repeat: -1,
    });
    this.anims.create({
      key: "boar-walk",
      frames: this.anims.generateFrameNumbers("boar-walk", {
        start: 0,
        end: 5,
      }),
      frameRate: 8,
      repeat: -1,
    });
    this.anims.create({
      key: "boar-vanish",
      frames: this.anims.generateFrameNumbers("boar-vanish", {
        start: 0,
        end: 3,
      }),
      frameRate: 12,
      repeat: 0,
    });
    this.anims.create({
      key: "black-boar-idle",
      frames: this.anims.generateFrameNumbers("black-boar-idle", {
        start: 0,
        end: 3,
      }),
      frameRate: 8,
      repeat: -1,
    });
    this.anims.create({
      key: "black-boar-run",
      frames: this.anims.generateFrameNumbers("black-boar-run", {
        start: 0,
        end: 5,
      }),
      frameRate: 10,
      repeat: -1,
    });
    this.anims.create({
      key: "black-boar-walk",
      frames: this.anims.generateFrameNumbers("black-boar-walk", {
        start: 0,
        end: 5,
      }),
      frameRate: 8,
      repeat: -1,
    });
    this.anims.create({
      key: "black-boar-vanish",
      frames: this.anims.generateFrameNumbers("black-boar-vanish", {
        start: 0,
        end: 3,
      }),
      frameRate: 12,
      repeat: 0,
    });

    this.setupRoomHandlers();
    this.setupInputHandlers();

    this.room.state.spawnedMonsters.onAdd((monster, index) => {
      const entity = this.physics.add.sprite(
        monster.x,
        monster.y,
        monster.name === "Boar" ? "boar-idle" : "black-boar-idle"
      );
      entity.setDisplaySize(48, 32);
      entity.setDepth(5);
      this.monsterEntities[monster.id] = entity; // Use monster.id
      this.createMonsterHealthBar(monster, monster.id); // Pass monster.id
      if (monster.name === "Black Boar") {
        entity.setScale(2);
        entity.setOrigin(0.5, 0.7);
      }

      this.room.onMessage("monster-took-damage", (data) => {
        if (data.monsterId === monster.id) {
          this.showDamageNumber(entity.x, entity.y, data.damage);
          entity.play(
            monster.name === "Boar" ? "boar-vanish" : "black-boar-vanish",
            true
          );
          entity.once("animationcomplete", () => {
            if (!entity || !entity.anims) return;
            this.updateMonsterAnimation(entity, monster);
          });
        }
      });

      monster.onChange(() => {
        if (!entity) return;
        entity.setData("serverX", monster.x);
        entity.setData("serverY", monster.y);
        entity.setData("behaviorState", monster.behaviorState);
        this.updateMonsterHealthBar(monster, monster.id); // Pass monster.id
        if (
          entity.anims &&
          (!entity.anims.isPlaying ||
            entity.anims.currentAnim?.key !==
              (monster.name === "Boar" ? "boar-vanish" : "black-boar-vanish"))
        ) {
          this.updateMonsterAnimation(entity, monster);
        }
        entity.setFlipX(monster.velocityX > 0);
      });
    });

    this.room.state.spawnedMonsters.onRemove((monster, index) => {
      const entity = this.monsterEntities[monster.id];
      if (entity) {
        entity.destroy();
        delete this.monsterEntities[monster.id];
      }
      this.removeMonsterHealthBar(monster.id); // Pass monster.id
    });
  }

  update(time: number, delta: number) {
    if (!this.currentPlayer) return;
    this.elapsedTime += delta;
    while (this.elapsedTime >= this.fixedTimeStep) {
      this.elapsedTime -= this.fixedTimeStep;
      this.fixedTick(time, this.fixedTimeStep);
    }
    super.updateInvulnerabilityEffect(this.currentPlayer);
    this.updateOtherPlayersEffects();
    this.updateHealthBar();
    this.updateHealthBarPosition(this.currentPlayer);
    for (const id in this.monsterEntities) {
      const entity = this.monsterEntities[id];
      if (entity) {
        const monster = this.room.state.spawnedMonsters.find(
          (m) => m.id === id
        );
        if (monster) this.updateMonsterHealthBar(monster, id); // Pass id
      }
    }
  }

  private updateMonsterAnimation(entity: any, monster: any) {
    const behaviorState = monster.behaviorState || "idle";
    switch (behaviorState) {
      case "run":
        entity.play(
          monster.name === "Boar" ? "boar-run" : "black-boar-run",
          true
        );
        break;
      case "walk":
        entity.play(
          monster.name === "Boar" ? "boar-walk" : "black-boar-walk",
          true
        );
        break;
      case "idle":
      default:
        entity.play(
          monster.name === "Boar" ? "boar-idle" : "black-boar-idle",
          true
        );
        break;
    }
  }
}
