import { BaseGameScene } from "./BaseGameScene";
import { AssetLoader } from "../utils/AssetLoader";

export class Village extends BaseGameScene {
  constructor() {
    super({ key: "village" });
  }

  preload() {
    AssetLoader.loadVillageAssets(this);
    this.load.tilemapTiledJSON("village-map", "assets/VillageMap.tmj");
    this.loadSounds();
    this.load.audio("village-music", "assets/Music/forestSyncopika.mp3");
  }

  async create() {
    this.room = this.registry.get("room");
    this.client = this.registry.get("client");

    if (this.room) {
      this.registry.set("room", this.room);

      // Initialize player data with current inventory
      const username = this.registry.get("playerData")?.username;
      if (username) {
        const player = this.room.state.spawnedPlayers.find(
          (p) => p.username === username
        );
        if (player && player.inventory) {
          // Convert MapSchema inventory to array format
          const inventoryArray: any[] = [];
          player.inventory.forEach((loot: any, key: string) => {
            inventoryArray.push({
              loot: loot,
              quantity: player.inventoryQuantities.get(key) || 0,
            });
          });

          // Update player data in registry
          const currentPlayerData = this.registry.get("playerData");
          if (currentPlayerData) {
            const updatedPlayerData = {
              ...currentPlayerData,
              inventory: inventoryArray,
            };
            this.registry.set("playerData", updatedPlayerData);

            // Emit event for inventory panel to update
            this.game.events.emit("inventory-updated");
          }
        }
      }

      if (!this.scene.isActive("chat")) this.scene.launch("chat");
      if (!this.scene.isActive("panel")) this.scene.launch("panel");
    }

    this.initSounds();
    this.playBackgroundMusic("village-music");
    super.createHealthBar();

    const map = this.setupMap("village-map", [
      ["MainLevBuild", "tileset"],
      ["VP2_Main", "buildings"],
    ]);
    this.setupPhysicsAndCamera(map.widthInPixels, map.heightInPixels);
    this.setupInputKeys();
    this.setupPortalCollisions();
    this.createPlayerAnimations();
    this.createLootAnimations();

    // Scene-specific monster animations
    this.anims.create({
      key: "Idle",
      frames: this.anims.generateFrameNumbers("Idle", { start: 0, end: 7 }),
      frameRate: 8,
      repeat: -1,
    });
    this.anims.create({
      key: "Dead",
      frames: this.anims.generateFrameNumbers("Dead", { start: 0, end: 7 }),
      frameRate: 8,
    });
    this.anims.create({
      key: "Hide",
      frames: this.anims.generateFrameNumbers("Hide", { start: 0, end: 3 }),
      frameRate: 8,
      repeat: -1,
    });

    this.setupRoomHandlers();
    this.setupInputHandlers();

    // Scene-specific monster handlers
    this.room.state.spawnedMonsters.onAdd((monster, index) => {
      try {
        const entity = this.physics.add.sprite(monster.x, monster.y, "Idle");
        console.log("DEBUG: updateMonsterAnimation", entity, monster);
        entity.setDisplaySize(48, 32);
        entity.play("Idle", true);
        this.monsterEntities[monster.id] = entity; // Use monster.id
        this.createMonsterHealthBar(monster, monster.id); // Pass monster.id
        entity.setDepth(5);

        this.room.onMessage("monster-took-damage", (data) => {
          if (data.monsterId === monster.id) {
            this.showDamageNumber(entity.x, entity.y, data.damage);
          }
        });

        monster.onChange(() => {
          if (!entity || !entity.anims) return;
          entity.setData("serverX", monster.x);
          entity.setData("serverY", monster.y);
          entity.setData("behaviorState", monster.behaviorState);
          if (monster.behaviorState === "idle") entity.stop();
          else entity.play("Idle", true);
          this.updateMonsterHealthBar(monster, monster.id); // Pass monster.id
          entity.setFlipX(monster.velocityX > 0);
        });
      } catch (error) {
        console.error("Error creating monster entity:", error);
      }
    });

    this.room.state.spawnedMonsters.onRemove((monster, index) => {
      const entity = this.monsterEntities[monster.id];
      if (entity) {
        entity.play("Dead", true);
        entity.once("animationcomplete", () => {
          entity.destroy();
          delete this.monsterEntities[monster.id];
        });
      }
      this.removeMonsterHealthBar(monster.id); // Pass monster.id
    });
  }

  update(time: number, delta: number) {
    if (!this.currentPlayer) return;
    this.elapsedTime += delta;
    while (this.elapsedTime >= this.fixedTimeStep) {
      this.elapsedTime -= this.fixedTimeStep;
      this.fixedTick(time, this.fixedTimeStep);
    }
    super.updateInvulnerabilityEffect(this.currentPlayer);
    this.updateOtherPlayersEffects();
    this.updateHealthBar();
    this.updateHealthBarPosition(this.currentPlayer);
    for (const id in this.monsterEntities) {
      const entity = this.monsterEntities[id];
      if (entity) {
        const monster = this.room.state.spawnedMonsters.find(
          (m) => m.id === id
        );
        if (monster) this.updateMonsterHealthBar(monster, id); // Pass id
      }
    }
  }
}
