import Phaser from "phaser";
import { AssetLoader } from "../utils/AssetLoader";

declare namespace RexUIPlugin {
  interface RexUI {
    add: any;
  }
}

const COLOR_PRIMARY = 0x4e342e;
const COLOR_LIGHT = 0x7b5e57;
const COLOR_DARK = 0x260e04;

export class PanelScene extends Phaser.Scene {
  private rexUI!: RexUIPlugin.RexUI;
  private print!: Phaser.GameObjects.Text;
  private gridTable: any;
  private inventoryItems: any[] = [];
  private totalCoins: number = 0;
  private coinCounterText: Phaser.GameObjects.Text | null = null;

  constructor() {
    super({ key: "panel" });
  }

  preload() {
    // Load the loot items spritesheet if not already loaded
    if (!this.textures.exists("loot-items")) {
      this.load.spritesheet(
        "loot-items",
        "assets/Loot/Freebies_Full_Icons.png",
        {
          frameWidth: 32,
          frameHeight: 32,
        }
      );
    }
  }

  create() {
    this.print = this.add.text(0, 0, "");

    // Initial setup of the inventory grid
    this.createInventoryGrid();

    // Listen for inventory updates
    this.game.events.on("inventory-updated", this.updateInventoryDisplay, this);

    // Add resize listener to keep inventory in bottom right corner
    this.scale.on("resize", this.updateInventoryPosition, this);

    // Get initial inventory data from registry if available
    this.updateInventoryDisplay();
  }

  createInventoryGrid() {
    const scrollMode = 0; // 0:vertical, 1:horizontal

    // Calculate dimensions based on window size
    const gridWidth = this.getInventoryWidth();
    const gridHeight = this.getInventoryHeight();
    const xPos = this.scale.width;
    const yPos = this.scale.height;

    this.gridTable = this.rexUI.add
      .gridTable({
        x: xPos - gridWidth / 2,
        y: yPos - gridHeight / 2,
        width: gridWidth,
        height: gridHeight,

        scrollMode: scrollMode,

        background: this.rexUI.add.roundRectangle(
          0,
          0,
          20,
          10,
          10,
          COLOR_PRIMARY
        ),

        table: {
          cellWidth: scrollMode === 0 ? 75 : 50,
          cellHeight: scrollMode === 0 ? 65 : 50,

          columns: 4,

          mask: {
            padding: 1,
          },

          reuseCellContainer: true,
        },

        slider: {
          track: this.rexUI.add.roundRectangle(0, 0, 15, 8, 8, COLOR_DARK),
          thumb: this.rexUI.add.roundRectangle(0, 0, 0, 0, 10, COLOR_LIGHT),
        },

        mouseWheelScroller: {
          focus: false,
          speed: 0.1,
        },

        header: this.rexUI.add.label({
          width: scrollMode === 0 ? undefined : 25,
          height: scrollMode === 0 ? 30 : undefined,

          orientation: scrollMode,
          background: this.rexUI.add.roundRectangle(
            0,
            0,
            20,
            20,
            0,
            COLOR_DARK
          ),
          text: this.add.text(0, 0, "Inventory").setFontSize(16),
        }),

        footer: this.getFooterSizer(scrollMode),

        space: {
          left: 10,
          right: 10,
          top: 10,
          bottom: 10,

          table: 5,
          header: 5,
          footer: 5,
        },

        createCellContainerCallback: (cell, cellContainer) => {
          const scene = cell.scene;
          const width = cell.width;
          const height = cell.height;
          const item = cell.item;
          const index = cell.index;

          if (cellContainer === null) {
            // Create new cell container with icon and text
            cellContainer = scene.rexUI.add.label({
              width: width,
              height: height,

              orientation: scrollMode,
              background: scene.rexUI.add
                .roundRectangle(0, 0, 20, 20, 8)
                .setStrokeStyle(1, COLOR_DARK),
              icon: scene.add.sprite(0, 0, "loot-items", 0),
              text: scene.add.text(0, 0, ""),

              space: {
                icon: 0,
                left: 2,
                right: 2,
                top: 2,
                bottom: 2,
              },
            });
          }

          // Set properties from item value
          cellContainer.setMinSize(width, height);

          // Get the icon sprite element
          const iconSprite = cellContainer.getElement(
            "icon"
          ) as Phaser.GameObjects.Sprite;

          // Get the quantity text element
          const quantityText = cellContainer.getElement(
            "text"
          ) as Phaser.GameObjects.Text;

          // Display the item icon and quantity
          if (item) {
            // Convert the display name to a camelCase key for the lookup
            const lootKey = this.convertToLootKey(item.name);
            const frameIndex = AssetLoader.getLootFrame(lootKey);

            // Update the existing icon's frame and visibility
            iconSprite.setFrame(frameIndex);
            iconSprite.setDisplaySize(35, 35);
            iconSprite.setAlpha(1);

            // Update the quantity text
            quantityText.setText(`x${item.quantity}`);
            quantityText.setFontSize(14);
            quantityText.setColor("#FFFFFF");

            // Position the quantity text at the bottom-right corner of the cell
            quantityText.setPosition(width / 2 - 8, height / 2 + 8);
          } else {
            // Hide the icon and clear text for empty cells
            iconSprite.setAlpha(0);
            quantityText.setText("");
          }

          // Update cell background
          cellContainer
            .getElement("background")
            .setStrokeStyle(1, COLOR_DARK)
            .setDepth(0);

          // Layout the icon and text
          cellContainer.layout();

          return cellContainer;
        },
        items: this.inventoryItems,
      })
      .layout();

    this.gridTable
      .on(
        "cell.over",
        function (cellContainer, cellIndex, pointer) {
          cellContainer
            .getElement("background")
            .setStrokeStyle(2, COLOR_LIGHT)
            .setDepth(1);
        },
        this
      )
      .on(
        "cell.out",
        function (cellContainer, cellIndex, pointer) {
          cellContainer
            .getElement("background")
            .setStrokeStyle(1, COLOR_DARK)
            .setDepth(0);
        },
        this
      )
      .on(
        "cell.click",
        function (cellContainer, cellIndex, pointer) {
          if (
            cellContainer &&
            cellContainer.text &&
            this.inventoryItems[cellIndex]
          ) {
            // Show item name on selection
            this.print.text = `${this.inventoryItems[cellIndex].name} x${this.inventoryItems[cellIndex].quantity}`;
            this.print.setPosition(
              this.gridTable.x - this.gridTable.width / 2,
              this.gridTable.y + this.gridTable.height / 2 + 8
            );
          }
        },
        this
      );
  }

  // Calculate inventory width based on window size
  getInventoryWidth() {
    return Math.min(
      320, // Base width
      this.scale.width * 0.25 // 25% of screen width
    );
  }

  // Calculate inventory height based on window size
  getInventoryHeight() {
    return Math.min(
      320, // Base height
      this.scale.height * 0.4 // 40% of screen height
    );
  }

  updateInventoryDisplay() {
    // Get player data from registry
    const playerData = this.registry.get("playerData");

    if (playerData && playerData.inventory) {
      // Reset total coins
      this.totalCoins = 0;

      // Filter out coin items and calculate their total value
      const nonCoinItems = playerData.inventory.filter((item: any) => {
        const name = item.loot.name;
        if (name.includes("Coin")) {
          // Add coin values to total (you might want to adjust multipliers based on coin type)
          if (name.includes("Small")) {
            this.totalCoins += item.quantity * 1;
          } else if (name.includes("Medium")) {
            this.totalCoins += item.quantity * 5;
          } else if (name.includes("Large")) {
            this.totalCoins += item.quantity * 10;
          }
          return false; // Filter out this coin
        }
        return true; // Keep non-coin items
      });

      // Update our local inventory items array
      this.inventoryItems = nonCoinItems.map((item: any) => ({
        name: item.loot.name,
        quantity: item.quantity,
        width: item.loot.width,
        height: item.loot.height,
      }));

      // Update the grid table with new items
      if (this.gridTable) {
        this.gridTable.setItems(this.inventoryItems);
      }

      // Update coin counter text
      if (this.coinCounterText) {
        this.coinCounterText.setText(`${this.totalCoins}`);
      }
    }

    // Update the inventory position to ensure it stays at the bottom right
    this.updateInventoryPosition();
  }

  // Method to update inventory position and size on window resize
  updateInventoryPosition() {
    if (!this.gridTable) return;

    // Calculate new dimensions based on window size
    const gridWidth = this.getInventoryWidth();
    const gridHeight = this.getInventoryHeight();

    // Position at bottom right corner
    this.gridTable.x = this.scale.width - gridWidth / 2;
    this.gridTable.y = this.scale.height - gridHeight / 2;

    // Update size
    this.gridTable.setMinSize(gridWidth, gridHeight);

    // Update layout after repositioning and resizing
    this.gridTable.layout();
  }

  // Helper method to convert display names to lootFrameMap keys
  convertToLootKey(displayName: string): string {
    // Convert "Small Coin" to "smallCoin", "White Feather" to "whiteFeather", etc.
    if (!displayName) return "";

    // Remove spaces and convert first letter to lowercase
    const parts = displayName.split(" ");
    return parts
      .map((part, index) => {
        if (index === 0) {
          return part.toLowerCase();
        }
        return part.charAt(0).toUpperCase() + part.slice(1);
      })
      .join("");
  }

  getItemColor(itemName: string): number {
    // Return different colors based on item category
    if (itemName.includes("Coin")) {
      return 0xffd700; // Gold for coins
    } else if (itemName.includes("Feather") || itemName.includes("Quill")) {
      // Return color based on the feather type
      if (itemName.includes("White")) return 0xffffff;
      if (itemName.includes("Grey")) return 0xaaaaaa;
      if (itemName.includes("Black")) return 0x333333;
      if (itemName.includes("Brown")) return 0x8b4513;
      if (itemName.includes("Red")) return 0xff0000;
      if (itemName.includes("Violet")) return 0x8a2be2;
      if (itemName.includes("Purple")) return 0x800080;
      if (itemName.includes("Blue")) return 0x0000ff;
      if (itemName.includes("Teal")) return 0x008080;
      if (itemName.includes("Green")) return 0x00ff00;
      if (itemName.includes("Yellow")) return 0xffff00;
      return 0x9370db; // Default for other feathers
    } else if (itemName === "Sword") {
      return 0xcccccc; // Silver for weapons
    } else {
      return 0xff6347; // Red/orange for fruits and other items
    }
  }

  getFooterSizer(scrollMode: number) {
    // Create a sizer for the footer that will hold both the close button and coin counter
    const footerSizer = this.rexUI.add.sizer({
      orientation: scrollMode,
      space: { item: 10 },
    });

    // Add coin counter with icon
    const coinIcon = this.add.sprite(0, 0, "coin", 0);
    coinIcon.setDisplaySize(16, 16);

    this.coinCounterText = this.add.text(0, 0, `${this.totalCoins}`, {
      fontSize: "14px",
      color: "#FFD700",
      fontStyle: "bold",
    });

    const coinCounter = this.rexUI.add.label({
      orientation: 0,
      icon: coinIcon,
      text: this.coinCounterText,
      space: { icon: 5 },
    });

    // Add the half-sized close button
    const closeButton = this.createFooterButton("Close", scrollMode);

    // Add both to the footer sizer
    footerSizer.add(coinCounter, 1, "left");
    footerSizer.add(closeButton, 1, "right");

    return footerSizer;
  }

  createFooterButton(text: string, orientation: number) {
    // Halve the size of the close button
    return this.rexUI.add
      .label({
        height: orientation === 0 ? 15 : undefined, // Halved from 30
        width: orientation === 0 ? undefined : 15, // Halved from 30
        orientation: orientation,
        background: this.rexUI.add.roundRectangle(0, 0, 2, 2, 15, COLOR_DARK),
        text: this.add.text(0, 0, text).setFontSize(12), // Reduced font size
        icon: this.rexUI.add.roundRectangle(0, 0, 0, 0, 8, COLOR_LIGHT),
        align: "center",
        space: {
          icon: 4, // Reduced from 8
        },
      })
      .setInteractive()
      .on("pointerdown", function () {
        console.log(`Pointer down ${text}`);
      })
      .on("pointerover", function () {
        this.getElement("background").setStrokeStyle(1, 0xffffff);
      })
      .on("pointerout", function () {
        this.getElement("background").setStrokeStyle();
      });
  }

  update() {}
}
