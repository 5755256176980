import { BaseGameScene } from "./BaseGameScene";
import { AssetLoader } from "../utils/AssetLoader";

export class House extends BaseGameScene {
  constructor() {
    super({ key: "house" });
  }

  preload() {
    AssetLoader.loadHouseAssets(this);
    this.load.tilemapTiledJSON("house-map", "assets/HouseMap.tmj");
    this.loadSounds();
    this.load.audio("village-music", "assets/Music/forestSyncopika.mp3");
  }

  async create() {
    this.room = this.registry.get("room");
    this.client = this.registry.get("client");

    if (this.room) {
      this.registry.set("room", this.room);

      // Initialize player data with current inventory
      const username = this.registry.get("playerData")?.username;
      if (username) {
        const player = this.room.state.spawnedPlayers.find(
          (p) => p.username === username
        );
        if (player && player.inventory) {
          // Convert MapSchema inventory to array format
          const inventoryArray: any[] = [];
          player.inventory.forEach((loot: any, key: string) => {
            inventoryArray.push({
              loot: loot,
              quantity: player.inventoryQuantities.get(key) || 0,
            });
          });

          // Update player data in registry
          const currentPlayerData = this.registry.get("playerData");
          if (currentPlayerData) {
            const updatedPlayerData = {
              ...currentPlayerData,
              inventory: inventoryArray,
            };
            this.registry.set("playerData", updatedPlayerData);

            // Emit event for inventory panel to update
            this.game.events.emit("inventory-updated");
          }
        }
      }

      if (!this.scene.isActive("chat")) this.scene.launch("chat");
      if (!this.scene.isActive("panel")) this.scene.launch("panel");

      // Set up room handlers to create player entities
      this.setupRoomHandlers();
    }

    this.initSounds();
    this.playBackgroundMusic("village-music");
    super.createHealthBar();

    const map = this.setupMap("house-map", [["VP2_Interriors", "interior"]]);
    this.setupPhysicsAndCamera(map.widthInPixels, map.heightInPixels);
    this.setupInputKeys();
    this.setupPortalCollisions();
    this.createPlayerAnimations();

    // Set up input handlers for player interaction
    this.setupInputHandlers();
  }

  // Add update method to process player movement and effects
  update(time: number, delta: number) {
    if (!this.currentPlayer) return;
    this.elapsedTime += delta;
    while (this.elapsedTime >= this.fixedTimeStep) {
      this.elapsedTime -= this.fixedTimeStep;
      this.fixedTick(time, this.fixedTimeStep);
    }
    super.updateInvulnerabilityEffect(this.currentPlayer);
    this.updateOtherPlayersEffects();
    this.updateHealthBar();
    this.updateHealthBarPosition(this.currentPlayer);
  }
}
