import Phaser from "phaser";
import { Client } from "colyseus.js";

export class Login extends Phaser.Scene {
  private client: Client;
  private form: HTMLFormElement;
  private usernameInput: HTMLInputElement;
  private passwordInput: HTMLInputElement;
  private loginButton: HTMLButtonElement;
  private registerButton: HTMLButtonElement;
  private errorText: HTMLDivElement;
  private backgroundImage: Phaser.GameObjects.Image;
  private creditsButton: HTMLButtonElement;
  private creditsModal: HTMLDivElement;

  constructor() {
    super({ key: "login" });
  }

  preload() {
    // Load login screen assets
    this.load.image("login-background", "assets/landscape.png");
  }

  create() {
    // Add background image
    this.backgroundImage = this.add.image(
      this.cameras.main.width / 2,
      this.cameras.main.height / 2,
      "login-background"
    );

    // Scale background to cover the screen
    const scaleX = this.cameras.main.width / this.backgroundImage.width;
    const scaleY = this.cameras.main.height / this.backgroundImage.height;
    const scale = Math.max(scaleX, scaleY);
    this.backgroundImage.setScale(scale).setScrollFactor(0);

    // Create HTML elements for login form
    this.createLoginForm();

    // Initialize Colyseus client
    //this.client = new Client("http://localhost:2567");
    this.client = new Client("https://platformer-bcdf5c8186fd.herokuapp.com");
    this.registry.set("client", this.client);

    // Add event listeners
    this.form.addEventListener("submit", (e) => {
      e.preventDefault();
      this.handleLogin();
    });
    this.registerButton.addEventListener("click", () => this.handleRegister());
  }

  private createLoginForm() {
    const container = document.createElement("div");
    container.id = "login-container";
    container.style.position = "absolute";
    container.style.top = "50%";
    container.style.left = "50%";
    container.style.transform = "translate(-50%, -50%)";
    container.style.backgroundColor = "rgba(30, 30, 60, 0.85)";
    container.style.padding = "30px";
    container.style.borderRadius = "10px";
    container.style.color = "#FFD700";
    container.style.zIndex = "1000";
    container.style.width = "300px";
    container.style.boxShadow =
      "0 0 20px rgba(0, 0, 0, 0.7), 0 0 40px rgba(100, 100, 255, 0.3)";
    container.style.border = "2px solid #8B4513";
    container.style.fontFamily = "'Cinzel', serif";

    // Add custom font
    const fontLink = document.createElement("link");
    fontLink.rel = "stylesheet";
    fontLink.href =
      "https://fonts.googleapis.com/css2?family=Cinzel:wght@400;700&family=MedievalSharp&display=swap";
    document.head.appendChild(fontLink);

    // Add title
    const title = document.createElement("h1");
    title.textContent = "Monster Hunter";
    title.style.textAlign = "center";
    title.style.marginBottom = "20px";
    title.style.color = "#FFD700";
    title.style.fontFamily = "'MedievalSharp', cursive";
    title.style.textShadow = "2px 2px 4px rgba(0, 0, 0, 0.7)";
    title.style.fontSize = "28px";

    this.form = document.createElement("form");
    this.form.style.display = "flex";
    this.form.style.flexDirection = "column";
    this.form.style.alignItems = "center";

    // Create label for username
    const usernameLabel = document.createElement("label");
    usernameLabel.textContent = "Username";
    usernameLabel.style.alignSelf = "flex-start";
    usernameLabel.style.marginBottom = "5px";
    usernameLabel.style.fontFamily = "'Cinzel', serif";

    this.usernameInput = document.createElement("input");
    this.usernameInput.type = "text";
    this.usernameInput.placeholder = "Enter your username";
    this.usernameInput.style.marginBottom = "15px";
    this.usernameInput.style.width = "100%";
    this.usernameInput.style.padding = "10px";
    this.usernameInput.style.borderRadius = "5px";
    this.usernameInput.style.border = "1px solid #8B4513";
    this.usernameInput.style.backgroundColor = "rgba(255, 255, 255, 0.9)";
    this.usernameInput.style.fontFamily = "Arial, sans-serif";
    this.usernameInput.style.textTransform = "none";
    this.usernameInput.style.textDecoration = "none";
    this.usernameInput.style.fontVariant = "normal";
    this.usernameInput.required = true;
    this.usernameInput.autocomplete = "username";
    this.usernameInput.id = "login-username-input";

    // Create label for password
    const passwordLabel = document.createElement("label");
    passwordLabel.textContent = "Password";
    passwordLabel.style.alignSelf = "flex-start";
    passwordLabel.style.marginBottom = "5px";
    passwordLabel.style.fontFamily = "'Cinzel', serif";

    this.passwordInput = document.createElement("input");
    this.passwordInput.type = "password";
    this.passwordInput.placeholder = "Enter your password";
    this.passwordInput.style.marginBottom = "20px";
    this.passwordInput.style.width = "100%";
    this.passwordInput.style.padding = "10px";
    this.passwordInput.style.borderRadius = "5px";
    this.passwordInput.style.border = "1px solid #8B4513";
    this.passwordInput.style.backgroundColor = "rgba(255, 255, 255, 0.9)";
    this.passwordInput.style.fontFamily = "Arial, sans-serif";
    this.passwordInput.style.textTransform = "none";
    this.passwordInput.style.textDecoration = "none";
    this.passwordInput.style.fontVariant = "normal";
    this.passwordInput.required = true;
    this.passwordInput.autocomplete = "current-password";
    this.passwordInput.id = "login-password-input";

    // Create button container
    const buttonContainer = document.createElement("div");
    buttonContainer.style.display = "flex";
    buttonContainer.style.justifyContent = "space-between";
    buttonContainer.style.width = "100%";
    buttonContainer.style.marginBottom = "15px";

    this.loginButton = document.createElement("button");
    this.loginButton.type = "submit";
    this.loginButton.textContent = "Login";
    this.loginButton.style.padding = "10px 20px";
    this.loginButton.style.backgroundColor = "#4b6cb7";
    this.loginButton.style.color = "white";
    this.loginButton.style.border = "none";
    this.loginButton.style.borderRadius = "5px";
    this.loginButton.style.cursor = "pointer";
    this.loginButton.style.fontFamily = "'Cinzel', serif";
    this.loginButton.style.fontWeight = "bold";
    this.loginButton.style.transition = "all 0.3s ease";
    this.loginButton.style.boxShadow = "0 2px 5px rgba(0, 0, 0, 0.2)";
    this.loginButton.onmouseover = () => {
      this.loginButton.style.backgroundColor = "#3a5795";
      this.loginButton.style.transform = "translateY(-2px)";
      this.loginButton.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.3)";
    };
    this.loginButton.onmouseout = () => {
      this.loginButton.style.backgroundColor = "#4b6cb7";
      this.loginButton.style.transform = "translateY(0)";
      this.loginButton.style.boxShadow = "0 2px 5px rgba(0, 0, 0, 0.2)";
    };

    this.registerButton = document.createElement("button");
    this.registerButton.type = "button";
    this.registerButton.textContent = "Register";
    this.registerButton.style.padding = "10px 20px";
    this.registerButton.style.backgroundColor = "#8B4513";
    this.registerButton.style.color = "white";
    this.registerButton.style.border = "none";
    this.registerButton.style.borderRadius = "5px";
    this.registerButton.style.cursor = "pointer";
    this.registerButton.style.fontFamily = "'Cinzel', serif";
    this.registerButton.style.fontWeight = "bold";
    this.registerButton.style.transition = "all 0.3s ease";
    this.registerButton.style.boxShadow = "0 2px 5px rgba(0, 0, 0, 0.2)";
    this.registerButton.onmouseover = () => {
      this.registerButton.style.backgroundColor = "#A0522D";
      this.registerButton.style.transform = "translateY(-2px)";
      this.registerButton.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.3)";
    };
    this.registerButton.onmouseout = () => {
      this.registerButton.style.backgroundColor = "#8B4513";
      this.registerButton.style.transform = "translateY(0)";
      this.registerButton.style.boxShadow = "0 2px 5px rgba(0, 0, 0, 0.2)";
    };

    this.errorText = document.createElement("div");
    this.errorText.style.color = "#FF6347";
    this.errorText.style.marginTop = "10px";
    this.errorText.style.textAlign = "center";
    this.errorText.style.fontFamily = "'Cinzel', serif";
    this.errorText.style.fontSize = "14px";

    buttonContainer.appendChild(this.loginButton);
    buttonContainer.appendChild(this.registerButton);

    container.appendChild(title);
    this.form.appendChild(usernameLabel);
    this.form.appendChild(this.usernameInput);
    this.form.appendChild(passwordLabel);
    this.form.appendChild(this.passwordInput);
    this.form.appendChild(buttonContainer);
    this.form.appendChild(this.errorText);

    // Add game instructions
    const instructionsContainer = document.createElement("div");
    instructionsContainer.style.marginTop = "20px";
    instructionsContainer.style.marginBottom = "25px";
    instructionsContainer.style.padding = "15px";
    instructionsContainer.style.backgroundColor = "rgba(0, 0, 0, 0.6)";
    instructionsContainer.style.borderRadius = "8px";
    instructionsContainer.style.border = "1px solid #8B4513";

    const instructionsTitle = document.createElement("h3");
    instructionsTitle.textContent = "Game Controls";
    instructionsTitle.style.color = "#FFD700";
    instructionsTitle.style.marginBottom = "10px";
    instructionsTitle.style.textAlign = "center";
    instructionsTitle.style.fontFamily = "'MedievalSharp', cursive";
    instructionsTitle.style.fontSize = "18px";

    const instructionsList = document.createElement("ul");
    instructionsList.style.listStyleType = "none";
    instructionsList.style.padding = "0";
    instructionsList.style.margin = "0";
    instructionsList.style.fontSize = "14px";

    // Control items with icons
    const controlItems = [
      {
        icon: `<div style="display:grid;grid-template-columns:1fr 1fr 1fr;grid-gap:1px;font-size:10px;text-align:center;">
                <div></div><div style="background:#333;border:1px solid #555;border-radius:2px;padding:1px;">W</div><div></div>
                <div style="background:#333;border:1px solid #555;border-radius:2px;padding:1px;">A</div>
                <div style="background:#333;border:1px solid #555;border-radius:2px;padding:1px;">S</div>
                <div style="background:#333;border:1px solid #555;border-radius:2px;padding:1px;">D</div>
               </div>`,
        text: "Move character",
      },
      {
        icon: `<div style="position:relative;width:24px;height:28px;background:#444;border-radius:12px;border:1px solid #666;">
                <div style="position:absolute;top:2px;left:2px;right:2px;height:10px;display:flex;">
                  <div style="flex:1;background:#f55;border-radius:8px 0 0 0;border-right:1px solid #666;"></div>
                  <div style="flex:1;background:#666;border-radius:0 8px 0 0;"></div>
                </div>
               </div>`,
        text: "Attack",
      },
      {
        icon: `<div style="background:#333;border:1px solid #555;border-radius:2px;padding:2px 5px;font-size:12px;">Z</div>`,
        text: "Loot items",
      },
      {
        icon: `<div style="background:#333;border:1px solid #555;border-radius:2px;padding:2px;width:25px;height:10px;"></div>`,
        text: "Jump",
      },
    ];

    controlItems.forEach((control) => {
      const item = document.createElement("li");
      item.style.display = "flex";
      item.style.alignItems = "center";
      item.style.marginBottom = "12px"; // Increased spacing between items
      item.style.fontFamily = "'Cinzel', serif";

      const iconSpan = document.createElement("span");
      iconSpan.innerHTML = control.icon;
      iconSpan.style.marginRight = "15px"; // Increased spacing between icon and text
      iconSpan.style.display = "inline-flex";
      iconSpan.style.justifyContent = "center";
      iconSpan.style.alignItems = "center";
      iconSpan.style.width = "35px";
      iconSpan.style.height = "35px";
      iconSpan.style.color = "#fff";

      const textSpan = document.createElement("span");
      textSpan.textContent = control.text;
      textSpan.style.color = "#FFFFFF";

      item.appendChild(iconSpan);
      item.appendChild(textSpan);
      instructionsList.appendChild(item);
    });

    instructionsContainer.appendChild(instructionsTitle);
    instructionsContainer.appendChild(instructionsList);
    container.appendChild(instructionsContainer);

    // Add credits button
    this.creditsButton = document.createElement("button");
    this.creditsButton.type = "button";
    this.creditsButton.textContent = "Credits";
    this.creditsButton.style.padding = "8px 15px";
    this.creditsButton.style.backgroundColor = "rgba(70, 70, 120, 0.8)";
    this.creditsButton.style.color = "#FFD700";
    this.creditsButton.style.border = "1px solid #8B4513";
    this.creditsButton.style.borderRadius = "5px";
    this.creditsButton.style.cursor = "pointer";
    this.creditsButton.style.fontFamily = "'Cinzel', serif";
    this.creditsButton.style.fontSize = "14px";
    this.creditsButton.style.marginTop = "10px";
    this.creditsButton.style.marginBottom = "10px";
    this.creditsButton.style.transition = "all 0.3s ease";
    this.creditsButton.style.boxShadow = "0 2px 5px rgba(0, 0, 0, 0.2)";
    this.creditsButton.style.alignSelf = "center";
    this.creditsButton.onmouseover = () => {
      this.creditsButton.style.backgroundColor = "rgba(90, 90, 150, 0.9)";
      this.creditsButton.style.transform = "translateY(-2px)";
      this.creditsButton.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.3)";
    };
    this.creditsButton.onmouseout = () => {
      this.creditsButton.style.backgroundColor = "rgba(70, 70, 120, 0.8)";
      this.creditsButton.style.transform = "translateY(0)";
      this.creditsButton.style.boxShadow = "0 2px 5px rgba(0, 0, 0, 0.2)";
    };

    // Create credits modal (hidden by default)
    this.creditsModal = document.createElement("div");
    this.creditsModal.style.display = "none";
    this.creditsModal.style.position = "fixed";
    this.creditsModal.style.top = "0";
    this.creditsModal.style.left = "0";
    this.creditsModal.style.width = "100%";
    this.creditsModal.style.height = "100%";
    this.creditsModal.style.backgroundColor = "rgba(0, 0, 0, 0.7)";
    this.creditsModal.style.zIndex = "2000";
    this.creditsModal.style.justifyContent = "center";
    this.creditsModal.style.alignItems = "center";

    const modalContent = document.createElement("div");
    modalContent.style.backgroundColor = "rgba(30, 30, 60, 0.95)";
    modalContent.style.padding = "30px";
    modalContent.style.borderRadius = "10px";
    modalContent.style.maxWidth = "500px";
    modalContent.style.width = "80%";
    modalContent.style.color = "#FFD700";
    modalContent.style.boxShadow = "0 0 30px rgba(100, 100, 255, 0.5)";
    modalContent.style.border = "2px solid #8B4513";
    modalContent.style.fontFamily = "'Cinzel', serif";
    modalContent.style.position = "relative";
    modalContent.style.animation = "fadeIn 0.3s ease-in-out";

    const modalTitle = document.createElement("h2");
    modalTitle.textContent = "Game Credits";
    modalTitle.style.textAlign = "center";
    modalTitle.style.marginBottom = "20px";
    modalTitle.style.color = "#FFD700";
    modalTitle.style.fontFamily = "'MedievalSharp', cursive";
    modalTitle.style.textShadow = "2px 2px 4px rgba(0, 0, 0, 0.7)";

    const creditsList = document.createElement("div");
    creditsList.style.marginBottom = "20px";

    const credits = [
      {
        role: "Forest Theme 1",
        name: "Attribute to Axton Crolley at freevideogamemusic@gmail.com",
      },
      { role: "Forest Theme 2", name: "syncopika" },
      { role: "Coin Collect", name: "bradwesson (Freesound)" },
      { role: "Sword Swing", name: "Merrick079 (Freesound)" },
      { role: "Jump", name: "Bastianhallo (Freesound" },
      { role: "Sword Hit", name: "u_xjrmmgxfru" },
      { role: "Portal Enter", name: "outroelison (Freesound)" },
      { role: "Spinning Coin", name: "trixie https://trixelized.itch.io/" },
    ];

    credits.forEach((credit) => {
      const creditItem = document.createElement("div");
      creditItem.style.marginBottom = "15px";

      const roleText = document.createElement("div");
      roleText.textContent = credit.role;
      roleText.style.fontWeight = "bold";
      roleText.style.color = "#FFD700";
      roleText.style.fontSize = "16px";

      const nameText = document.createElement("div");
      nameText.textContent = credit.name;
      nameText.style.color = "#FFFFFF";
      nameText.style.fontSize = "14px";
      nameText.style.marginLeft = "15px";

      creditItem.appendChild(roleText);
      creditItem.appendChild(nameText);
      creditsList.appendChild(creditItem);
    });

    const closeButton = document.createElement("button");
    closeButton.textContent = "Close";
    closeButton.style.padding = "8px 20px";
    closeButton.style.backgroundColor = "#4b6cb7";
    closeButton.style.color = "white";
    closeButton.style.border = "none";
    closeButton.style.borderRadius = "5px";
    closeButton.style.cursor = "pointer";
    closeButton.style.fontFamily = "'Cinzel', serif";
    closeButton.style.fontWeight = "bold";
    closeButton.style.display = "block";
    closeButton.style.margin = "0 auto";
    closeButton.style.transition = "all 0.3s ease";
    closeButton.onmouseover = () => {
      closeButton.style.backgroundColor = "#3a5795";
      closeButton.style.transform = "translateY(-2px)";
    };
    closeButton.onmouseout = () => {
      closeButton.style.backgroundColor = "#4b6cb7";
      closeButton.style.transform = "translateY(0)";
    };
    closeButton.onclick = () => {
      this.creditsModal.style.display = "none";
    };

    modalContent.appendChild(modalTitle);
    modalContent.appendChild(creditsList);
    modalContent.appendChild(closeButton);
    this.creditsModal.appendChild(modalContent);

    // Add event listener to credits button
    this.creditsButton.addEventListener("click", () => {
      this.creditsModal.style.display = "flex";
    });

    // Add event listener to close modal when clicking outside
    this.creditsModal.addEventListener("click", (e) => {
      if (e.target === this.creditsModal) {
        this.creditsModal.style.display = "none";
      }
    });

    container.appendChild(this.form);
    container.appendChild(this.creditsButton);
    document.body.appendChild(container);
    document.body.appendChild(this.creditsModal);

    // Add animation to the container
    container.style.animation = "fadeIn 0.5s ease-in-out";

    // Add keyframes for the animation
    const style = document.createElement("style");
    style.textContent = `
      @keyframes fadeIn {
        from { opacity: 0; transform: translate(-50%, -55%); }
        to { opacity: 1; transform: translate(-50%, -50%); }
      }
      
      @keyframes glow {
        0% { box-shadow: 0 0 20px rgba(0, 0, 0, 0.7), 0 0 40px rgba(100, 100, 255, 0.3); }
        50% { box-shadow: 0 0 25px rgba(0, 0, 0, 0.7), 0 0 50px rgba(100, 100, 255, 0.5); }
        100% { box-shadow: 0 0 20px rgba(0, 0, 0, 0.7), 0 0 40px rgba(100, 100, 255, 0.3); }
      }
      
      /* Ensure inputs don't have capitalization */
      #login-username-input, #login-password-input {
        text-transform: none !important;
        font-variant: normal !important;
        letter-spacing: normal !important;
        font-family: Arial, sans-serif !important;
        font-weight: normal !important;
        font-style: normal !important;
        -webkit-font-feature-settings: normal !important;
        font-feature-settings: normal !important;
      }
    `;
    document.head.appendChild(style);

    // Add subtle glow animation to the container
    container.style.animation = "fadeIn 0.5s ease-in-out, glow 3s infinite";
  }

  //on login, we first submit username and password, and wait for the response
  //the server should respond with the persisted player data and password
  //we set the persisted player data and password in the registry
  //then we connect to the last room or default to field, using the player data
  //and we start the game scene

  private async handleLogin() {
    // Show loading state
    this.loginButton.disabled = true;
    this.loginButton.textContent = "Logging in...";
    this.loginButton.style.backgroundColor = "#888";

    const username = this.usernameInput.value;
    const password = this.passwordInput.value;

    try {
      const response = await fetch(
        //"http://localhost:2567/auth/login",
        "https://platformer-bcdf5c8186fd.herokuapp.com/auth/login",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ username, password }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        this.errorText.textContent = data.error;
        // Reset button state
        this.loginButton.disabled = false;
        this.loginButton.textContent = "Login";
        this.loginButton.style.backgroundColor = "#4b6cb7";
        return;
      }
      //so if I kill a monster and gain exp what happens?
      //first of all exp is part of my player state in the colyseus room, so its accessible whenever
      //persistent data doesn't have to be up to date in real time, only when the player quits the game
      //second of all we save when the player quits the game
      //data in frontend registry to enable movement between rooms
      this.registry.set("playerData", data.playerData);
      this.registry.set("password", password);

      // Connect to the last room or default to field
      const lastRoom = data.playerData.lastRoom || "field";
      await this.connectToRoom(lastRoom);
    } catch (error) {
      console.error("Login error:", error);
      this.errorText.textContent = "Error connecting to server";
      // Reset button state
      this.loginButton.disabled = false;
      this.loginButton.textContent = "Login";
      this.loginButton.style.backgroundColor = "#4b6cb7";
    }
  }

  private async handleRegister() {
    // Show loading state
    this.registerButton.disabled = true;
    this.registerButton.textContent = "Registering...";
    this.registerButton.style.backgroundColor = "#888";

    const username = this.usernameInput.value;
    const password = this.passwordInput.value;

    try {
      const response = await fetch(
        //"http://localhost:2567/auth/register",
        "https://platformer-bcdf5c8186fd.herokuapp.com/auth/register",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ username, password }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        this.errorText.textContent = data.error;
        // Reset button state
        this.registerButton.disabled = false;
        this.registerButton.textContent = "Register";
        this.registerButton.style.backgroundColor = "#8B4513";
        return;
      }

      // After successful registration, attempt login
      this.registerButton.textContent = "Success! Logging in...";
      await this.handleLogin();
    } catch (error) {
      this.errorText.textContent = "Error connecting to server";
      console.error(error);
      // Reset button state
      this.registerButton.disabled = false;
      this.registerButton.textContent = "Register";
      this.registerButton.style.backgroundColor = "#8B4513";
    }
  }

  private async connectToRoom(roomName: string) {
    try {
      const playerData = this.registry.get("playerData");

      const room = await this.client.join(roomName, {
        username: playerData.username,
        password: this.registry.get("password"),
      });

      // Store room reference in registry
      this.registry.set("room", room);

      // Remove only the login container instead of clearing the entire body
      const loginContainer = document.getElementById("login-container");
      if (loginContainer) {
        // Add fade-out animation
        loginContainer.style.animation = "fadeOut 0.5s ease-in-out forwards";

        // Add keyframes for the animation if not already added
        if (!document.querySelector("style[data-fade-out]")) {
          const style = document.createElement("style");
          style.setAttribute("data-fade-out", "true");
          style.textContent = `
            @keyframes fadeOut {
              from { opacity: 1; transform: translate(-50%, -50%); }
              to { opacity: 0; transform: translate(-50%, -55%); }
            }
          `;
          document.head.appendChild(style);
        }

        // Remove after animation completes
        setTimeout(() => {
          loginContainer.remove();
        }, 500);
      }

      // Start the game scene
      this.scene.start(roomName);
    } catch (error) {
      console.error("Room connection error:", error);
      this.errorText.textContent = "Error joining room";

      // Reset button states
      this.loginButton.disabled = false;
      this.loginButton.textContent = "Login";
      this.loginButton.style.backgroundColor = "#4b6cb7";

      this.registerButton.disabled = false;
      this.registerButton.textContent = "Register";
      this.registerButton.style.backgroundColor = "#8B4513";
    }
  }

  shutdown() {
    // Clean up only the login form if it exists
    const loginContainer = document.getElementById("login-container");
    if (loginContainer) {
      loginContainer.remove();
    }

    // Remove any added styles
    const fadeOutStyle = document.querySelector("style[data-fade-out]");
    if (fadeOutStyle) {
      fadeOutStyle.remove();
    }

    // Stop the chat scene if it's active when logging out
    if (this.scene.get("chat") && this.scene.isActive("chat")) {
      this.scene.stop("chat");
    }

    // Remove the credits modal when scene is shut down
    if (this.creditsModal && this.creditsModal.parentNode) {
      this.creditsModal.parentNode.removeChild(this.creditsModal);
    }
  }
}
