export default {
    getMaxInputRowTitleWidth() {
        // Ignore Title of InputRows
        return 0;
    },

    setInputRowTitleWidth(width) {
        // Ignore Title of InputRows
        return this;
    }
}