import Phaser from "phaser";
import { Room, Client } from "colyseus.js";
import { AssetLoader } from "../utils/AssetLoader";

export class BaseGameScene extends Phaser.Scene {
  // Health bar UI elements
  protected healthBarBackground: Phaser.GameObjects.Rectangle;
  protected healthBar: Phaser.GameObjects.Rectangle;
  protected healthText: Phaser.GameObjects.Text;
  protected playerHealth: number = 100;
  protected playerMaxHealth: number = 100;
  protected isPlayerInvulnerable: boolean = false;

  // Container for health bar elements
  protected healthBarContainer: Phaser.GameObjects.Container;

  protected sounds: {
    collectcoin?: Phaser.Sound.BaseSound;
    swordslash?: Phaser.Sound.BaseSound;
    enemyhit?: Phaser.Sound.BaseSound;
    portalenter?: Phaser.Sound.BaseSound;
    jump?: Phaser.Sound.BaseSound;
    backgroundMusic?: Phaser.Sound.BaseSound;
  } = {};

  // Common properties for game scenes
  public room: Room;
  public client: Client;
  public currentPlayer: Phaser.Types.Physics.Arcade.SpriteWithDynamicBody;
  public playerEntities: {
    [username: string]: Phaser.Types.Physics.Arcade.SpriteWithDynamicBody;
  } = {};
  public monsterEntities: {
    [index: number]: Phaser.Types.Physics.Arcade.SpriteWithDynamicBody;
  } = {};
  public lootEntities: {
    [index: number]: Phaser.Types.Physics.Arcade.SpriteWithDynamicBody;
  } = {};
  public playerNameTags: { [username: string]: Phaser.GameObjects.Text } = {};
  public monsterHealthBars: { [id: string]: Phaser.GameObjects.Container } = {};
  public monsterNameTags: { [id: string]: Phaser.GameObjects.Text } = {};
  public chatFocused: boolean = false;

  // Tilemap-related properties
  protected map: Phaser.Tilemaps.Tilemap;
  protected tileset: Phaser.Tilemaps.Tileset;
  protected layer: Phaser.Tilemaps.TilemapLayer;
  protected layers: Phaser.Tilemaps.TilemapLayer[] = [];

  protected lootFrameMap: { [key: string]: number } = {};

  // Input-related properties
  protected cursorKeys: Phaser.Types.Input.Keyboard.CursorKeys;
  protected collectKey: Phaser.Input.Keyboard.Key;
  protected wKey: Phaser.Input.Keyboard.Key;
  protected aKey: Phaser.Input.Keyboard.Key;
  protected sKey: Phaser.Input.Keyboard.Key;
  protected dKey: Phaser.Input.Keyboard.Key;

  // Time-related properties
  protected elapsedTime = 0;
  protected fixedTimeStep = 1000 / 60;
  protected currentTick = 0;

  // Collision-related properties
  protected obstacles: Phaser.GameObjects.Rectangle[] = [];

  // Input payload structure
  protected inputPayload = {
    left: false,
    right: false,
    up: false,
    down: false,
    jump: false,
    attack: false,
    loot: false,
    tick: 0,
    username: "",
  };

  // Networking-related properties
  protected portalCooldown = 0;
  protected pendingInputs: any[] = [];
  protected reconciliationThreshold = 75;

  constructor(config: Phaser.Types.Scenes.SettingsConfig) {
    super(config);
    // Initialize loot frame map using AssetLoader
    this.lootFrameMap = {}; // Clear existing map
  }

  /** Sets up the tilemap and background */
  protected setupMap(
    tilemapKey: string,
    tilesets: [string, string][],
    backgroundKey: string = "forest-bg"
  ): Phaser.Tilemaps.Tilemap {
    this.map = this.make.tilemap({ key: tilemapKey });

    // Debug tilemap data
    console.log("Tilemap data:", this.map);

    const tilesetObjects = tilesets.map(([tilename, key]) => {
      const tileset = this.map.addTilesetImage(tilename, key);
      // Debug each tileset
      console.log(`Tileset ${tilename} -> ${key}:`, tileset);
      return tileset;
    });

    // Check if any tilesets failed to load
    if (tilesetObjects.some((t) => !t)) {
      console.error("Some tilesets failed to load:", tilesets);
    }

    // Clear previous layers array
    this.layers = [];

    // Process all layers instead of just the first one
    console.log(`Found ${this.map.layers.length} layers in the tilemap`);

    for (let i = 0; i < this.map.layers.length; i++) {
      const layerData = this.map.layers[i];
      const layerName = layerData?.name || `Tile Layer ${i + 1}`;
      console.log(`Creating layer ${i}: ${layerName}`);

      const layer = this.map.createLayer(layerName, tilesetObjects, 0, 0);

      if (layer) {
        layer.setVisible(true);
        layer.setActive(true);
        if (i === 2) {
          layer.setDepth(30);
          //the third layer is the trees which should be on top of everything
        } else {
          layer.setDepth(i);
        }
        console.log(`Layer ${layerName} created successfully:`, layer);
        this.layers.push(layer);
      } else {
        console.error(`Failed to create layer ${layerName}`);
      }
    }

    // Set the first layer as the main layer for backward compatibility
    if (this.layers.length > 0) {
      this.layer = this.layers[0];
    } else {
      console.error(
        "No layers were created. Available layers:",
        this.map.layers
      );
    }

    const mapWidth = this.map.widthInPixels;
    const mapHeight = this.map.heightInPixels;
    const bg = this.add.image(0, 0, backgroundKey);
    bg.setOrigin(0, 0);
    bg.setScrollFactor(0.6);
    bg.setDisplaySize(mapWidth, mapHeight);
    bg.setDepth(-10);
    return this.map;
  }

  /** Configures physics world and camera bounds */
  protected setupPhysicsAndCamera(mapWidth: number, mapHeight: number) {
    this.physics.world.setBounds(0, 0, mapWidth, mapHeight);
    this.cameras.main.setBounds(0, 0, mapWidth, mapHeight);

    // Set up collisions for all layers
    if (this.layers.length > 0) {
      // Enable collision for all layers based on the 'collision' property
      this.layers.forEach((layer) => {
        if (layer) {
          layer.setCollisionByProperty({ collision: true });
          console.log(`Enabled collisions for layer: ${layer.layer.name}`);
        }
      });
    } else if (this.layer) {
      // Fallback for backward compatibility
      this.layer.setCollisionByProperty({ collision: true });
      console.log(`Enabled collisions for layer: ${this.layer.layer.name}`);
    }
  }

  /** Initializes input keys */
  protected setupInputKeys() {
    this.cursorKeys = this.input.keyboard.createCursorKeys();
    this.collectKey = this.input.keyboard.addKey("Z");
    this.wKey = this.input.keyboard.addKey("W");
    this.aKey = this.input.keyboard.addKey("A");
    this.sKey = this.input.keyboard.addKey("S");
    this.dKey = this.input.keyboard.addKey("D");
  }

  /** Creates common player animations */
  protected createPlayerAnimations() {
    this.anims.create({
      key: "character-idle",
      frames: this.anims.generateFrameNumbers("character-idle", {
        start: 0,
        end: 3,
      }),
      frameRate: 8,
      repeat: -1,
    });
    this.anims.create({
      key: "character-run",
      frames: this.anims.generateFrameNumbers("character-run", {
        start: 0,
        end: 7,
      }),
      frameRate: 12,
      repeat: -1,
    });
    this.anims.create({
      key: "character-jump",
      frames: this.anims.generateFrameNumbers("character-jump", {
        start: 0,
        end: 14,
      }),
      frameRate: 15,
      repeat: 0,
    });
    this.anims.create({
      key: "character-attack",
      frames: this.anims.generateFrameNumbers("character-attack", {
        start: 0,
        end: 7,
      }),
      frameRate: 12,
      repeat: 0,
    });
    this.anims.create({
      key: "character-dead",
      frames: this.anims.generateFrameNumbers("character-dead", {
        start: 0,
        end: 5,
      }),
      frameRate: 10,
      repeat: 0,
    });

    // Level up animation
    this.anims.create({
      key: "level-up-animation",
      frames: this.anims.generateFrameNumbers("level-up-effect", {
        start: 0,
        end: 11, // 12 frames (0-11)
      }),
      frameRate: 8, // Reduced from 10 to 8 to make animation last longer
      repeat: 0,
    });
  }

  /** Creates common loot animations */
  protected createLootAnimations() {
    this.anims.create({
      key: "coin-spin",
      frames: this.anims.generateFrameNumbers("coin", { start: 0, end: 9 }),
      frameRate: 10,
      repeat: -1,
    });
  }

  /** Sets up handlers for room state (players, obstacles, loot) */
  protected setupRoomHandlers() {
    this.room.state.obstacles.onAdd((obstacle) => {
      const sprite = this.add.rectangle(
        obstacle.x,
        obstacle.y,
        obstacle.width,
        obstacle.height
      );
      if (obstacle.isOneWayPlatform) {
        sprite.setData("isOneWayPlatform", true);
      }
      this.obstacles.push(sprite);
    });

    // Add level up event listener
    this.room.onMessage("player-level-up", (data) => {
      this.handlePlayerLevelUp(data.username, data.newLevel);
    });

    this.room.state.spawnedPlayers.onAdd((player) => {
      const entity = this.physics.add.sprite(
        player.x,
        player.y,
        "character-idle"
      );
      entity.setDisplaySize(48, 48);
      entity.setOrigin(0.5, 0.65);
      entity.setDepth(10);
      this.playerEntities[player.username] = entity;

      const nameTag = this.add.text(player.x, player.y - 65, player.username, {
        fontSize: "14px",
        color: "#ffffff",
        stroke: "#000000",
        strokeThickness: 3,
        fontFamily: "Arial",
      });
      nameTag.setOrigin(0.5, 1);
      nameTag.setDepth(10);
      this.playerNameTags[player.username] = nameTag;

      if (player.username === this.registry.get("playerData").username) {
        this.currentPlayer = entity;
        this.cameras.main.startFollow(this.currentPlayer, true, 0.1, 0.1);
        this.cameras.main.setZoom(2);
        this.inputPayload.username = player.username;
        this.playerHealth = player.currentHealth;
        this.playerMaxHealth = player.maxHealth;
        this.isPlayerInvulnerable = player.isInvulnerable;
        this.updateHealthBar();
        player.onChange(() => {
          this.handleServerReconciliation(player);

          // Update player data in registry when it changes
          const currentPlayerData = this.registry.get("playerData");
          if (currentPlayerData) {
            // Convert MapSchema inventory to array format
            const inventoryArray: any[] = [];
            if (player.inventory) {
              player.inventory.forEach((loot: any, key: string) => {
                inventoryArray.push({
                  loot: loot,
                  quantity: player.inventoryQuantities.get(key) || 0,
                });
              });
            }

            const updatedPlayerData = {
              ...currentPlayerData,
              experience: player.experience,
              level: player.level,
              strength: player.strength,
              maxHealth: player.maxHealth,
              inventory: inventoryArray,
            };
            this.registry.set("playerData", updatedPlayerData);

            // Emit event for inventory panel to update
            this.game.events.emit("inventory-updated");
          }
        });
      } else {
        player.onChange(() => {
          entity.setData("serverX", player.x);
          entity.setData("serverY", player.y);
          entity.setData("velocityX", player.velocityX);
          entity.setData("velocityY", player.velocityY);
          entity.setData("canJump", player.canJump);
          entity.setData("canAttack", player.canAttack);
          entity.setData("canLoot", player.canLoot);
          entity.setData("isAttacking", player.isAttacking);
          entity.setData("height", player.height);
          entity.setData("width", player.width);
          entity.setData("experience", player.experience);
          entity.setData("level", player.level);
          entity.setData("username", player.username);
          entity.setData("id", player.id);
          const prevHealth =
            entity.getData("currentHealth") || player.currentHealth;
          if (prevHealth > player.currentHealth) {
            entity.setTint(0xff0000);
            this.time.delayedCall(200, () => entity.clearTint());
          }
          entity.setData("currentHealth", player.currentHealth);
          entity.setData("maxHealth", player.maxHealth);
          entity.setData("isInvulnerable", player.isInvulnerable);
          if (this.playerNameTags[player.username]) {
            this.playerNameTags[player.username].setPosition(
              player.x,
              player.y - 65
            );
          }
        });
      }
    });

    this.room.state.spawnedPlayers.onRemove((player) => {
      const entity = this.playerEntities[player.username];
      if (entity) {
        entity.destroy();
        delete this.playerEntities[player.username];
      }
      const nameTag = this.playerNameTags[player.username];
      if (nameTag) {
        nameTag.destroy();
        delete this.playerNameTags[player.username];
      }
    });

    this.setupLootHandlers();
  }

  /** Sets up input handlers for attacking */
  protected setupInputHandlers() {
    this.input.on("pointerdown", (pointer) => {
      if (
        pointer.leftButtonDown() &&
        this.currentPlayer &&
        this.room.state.spawnedPlayers.find(
          (p) => p.username === this.registry.get("playerData").username
        )?.canAttack
      ) {
        this.currentPlayer.setData("queuedAttack", true);
        this.currentPlayer.setData("isAttacking", true);
        setTimeout(() => this.currentPlayer.setData("isAttacking", false), 500);
      }
    });
  }

  // Health bar positioning
  public updateHealthBarPosition(playerEntity: any) {
    if (!this.healthBarContainer || !playerEntity) return;
    this.healthBarContainer.x = playerEntity.x;
    this.healthBarContainer.y = playerEntity.y - 30;
    this.healthBarContainer.setVisible(true);
    this.healthBarContainer.setAlpha(1);
  }

  protected createHealthBar() {
    if (this.healthBarBackground) this.healthBarBackground.destroy();
    if (this.healthBar) this.healthBar.destroy();
    if (this.healthText) this.healthText.destroy();
    if (this.healthBarContainer) this.healthBarContainer.destroy();

    this.healthBarContainer = this.add.container(0, 0);
    this.healthBarContainer.setDepth(10);

    this.healthBar = this.add.rectangle(0, 5, 66, 8, 0x00ff00);
    this.healthBar.setOrigin(0.5, 0.5);

    this.healthText = this.add.text(
      0,
      5,
      `${Math.ceil(this.playerHealth)}/${this.playerMaxHealth}`,
      {
        fontSize: "10px",
        color: "#ffffff",
        fontStyle: "bold",
        stroke: "#000000",
        strokeThickness: 2,
      }
    );
    this.healthText.setOrigin(0.5, 0.5);

    this.healthBarContainer.add([this.healthBar, this.healthText]);
    this.updateHealthBar();
  }

  protected updateHealthBar() {
    if (!this.healthBar) return;
    const healthPercent = Math.max(
      0,
      Math.min(1, this.playerHealth / this.playerMaxHealth)
    );
    const fullWidth = 66;
    this.healthBar.width = fullWidth * healthPercent;
    let color = 0x00ff00;
    if (healthPercent < 0.6) color = 0xffff00;
    if (healthPercent < 0.3) color = 0xff0000;
    this.healthBar.fillColor = color;
    if (this.healthText) {
      this.healthText.setText(
        `${Math.ceil(this.playerHealth)}/${this.playerMaxHealth}`
      );
    }
    if (this.isPlayerInvulnerable) {
      this.healthBar.fillColor = 0xffffff;
      this.time.delayedCall(100, () => (this.healthBar.fillColor = color));
    }
  }

  protected updateInvulnerabilityEffect(
    currentPlayer: Phaser.GameObjects.Sprite
  ) {
    if (!currentPlayer) return;
    if (this.isPlayerInvulnerable) {
      const flashRate = 100;
      const shouldBeVisible = Math.floor(this.time.now / flashRate) % 2 === 0;
      currentPlayer.setAlpha(shouldBeVisible ? 1 : 0.7);
      currentPlayer.setTint(shouldBeVisible ? 0xffffff : 0xff9999);
    } else {
      currentPlayer.setAlpha(1);
      currentPlayer.clearTint();
    }
  }

  protected handlePlayerHealthChange(
    player: any,
    currentPlayer: Phaser.GameObjects.Sprite
  ) {
    if (
      this.playerHealth !== player.currentHealth ||
      this.isPlayerInvulnerable !== player.isInvulnerable
    ) {
      if (this.playerHealth > player.currentHealth) {
        currentPlayer.setTint(0xff0000);
        this.time.delayedCall(200, () => currentPlayer.clearTint());
      }
      this.playerHealth = player.currentHealth;
      this.playerMaxHealth = player.maxHealth;
      this.isPlayerInvulnerable = player.isInvulnerable;
      this.updateHealthBar();
    }
  }

  protected setupLootHandlers() {
    if (!this.room || !this.room.state.spawnedLoot) return;
    Object.values(this.lootEntities).forEach((entity) => entity?.destroy());
    this.lootEntities = {};

    this.room.state.spawnedLoot.onAdd((loot, index) => {
      let entity;
      if (loot.name.includes("Coin")) {
        // Coin loot uses the coin spritesheet with animation
        entity = this.physics.add.sprite(loot.x, loot.y, "coin");
        entity.setDisplaySize(16, 16); // Coin size
        this.updateLootAnimation(entity, loot);
      } else {
        // Non-coin loot uses the loot-items spritesheet with a static frame
        // Convert the display name to a camelCase key for the lookup
        const lootKey = this.convertToLootKey(loot.name);
        const frameIndex = AssetLoader.getLootFrame(lootKey);
        console.log(
          "FRAME INDEX for",
          loot.name,
          "using key",
          lootKey,
          ":",
          frameIndex
        );
        entity = this.physics.add.sprite(
          loot.x,
          loot.y,
          "loot-items",
          frameIndex
        );
        entity.setDisplaySize(24, 24); // Reduced from 32x32 to 24x24
      }
      entity.setData("lootId", loot.id);
      entity.setData("lootIndex", index);
      entity.setDepth(20);
      this.lootEntities[index] = entity;

      loot.onChange(() => {
        entity.setData("serverX", loot.x);
        entity.setData("serverY", loot.y);
        if (loot.isBeingCollected && !entity.getData("beingCollected")) {
          entity.setData("beingCollected", true);
        }
      });
    });

    this.room.state.spawnedLoot.onRemove((loot, index) => {
      if (loot.collectedBy === this.registry.get("playerData")?.username) {
        this.playCollectCoinSound();
      }
      const entity = this.lootEntities[index];
      if (entity) {
        this.tweens.add({
          targets: entity,
          alpha: 0,
          y: entity.y - 20,
          duration: 200,
          onComplete: () => {
            entity.destroy();
            delete this.lootEntities[index];
          },
        });
      }
    });
  }

  protected updateLootAnimation(entity: any, loot: any) {
    if (loot.name.includes("Coin")) {
      if (loot.name.includes("Small Coin")) entity.setTint(0xcd7f32); // Bronze
      else if (loot.name.includes("Medium Coin"))
        entity.setTint(0xa8b8c0); // Silver
      else entity.clearTint();
      entity.play("coin-spin", true);
    }
    // Non-coin items (e.g., Feather) use static frames, so no animation or tint needed
  }

  // Helper method to convert display names to lootFrameMap keys
  protected convertToLootKey(displayName: string): string {
    // Convert "Small Coin" to "smallCoin", "White Feather" to "whiteFeather", etc.
    if (!displayName) return "";

    // Remove spaces and convert first letter to lowercase
    const parts = displayName.split(" ");
    return parts
      .map((part, index) => {
        if (index === 0) {
          return part.toLowerCase();
        }
        return part.charAt(0).toUpperCase() + part.slice(1);
      })
      .join("");
  }

  protected checkCollision(
    player: Phaser.Types.Physics.Arcade.ImageWithDynamicBody,
    obstacle:
      | Phaser.GameObjects.Rectangle
      | Phaser.Types.Physics.Arcade.SpriteWithDynamicBody
  ): boolean {
    const playerSize = 16;
    const obstacleHalfWidth = obstacle.width / 2;
    const obstacleHalfHeight = obstacle.height / 2;

    const playerLeft = player.x - playerSize;
    const playerRight = player.x + playerSize;
    const playerTop = player.y - playerSize;
    const playerBottom = player.y + playerSize;

    const obstacleLeft = obstacle.x - obstacleHalfWidth;
    const obstacleRight = obstacle.x + obstacleHalfWidth;
    const obstacleTop = obstacle.y - obstacleHalfHeight;
    const obstacleBottom = obstacle.y + obstacleHalfHeight;

    const isOneWayPlatform = obstacle.getData("isOneWayPlatform");
    if (isOneWayPlatform) {
      const velocityY = player.getData("velocityY") || 0;
      const prevY = player.getData("prevY") || player.y;
      const prevPlayerBottom = prevY + playerSize;
      if (velocityY >= 0 && prevPlayerBottom <= obstacleTop) {
        return (
          playerRight > obstacleLeft &&
          playerLeft < obstacleRight &&
          playerBottom > obstacleTop &&
          playerTop < obstacleBottom
        );
      }
      return false;
    }

    return (
      playerRight > obstacleLeft &&
      playerLeft < obstacleRight &&
      playerBottom > obstacleTop &&
      playerTop < obstacleBottom
    );
  }

  protected checkPortalCollision(
    player: Phaser.Types.Physics.Arcade.SpriteWithDynamicBody,
    portal: any
  ): boolean {
    const playerSize = 16;
    const portalHalfWidth = portal.width / 2;
    const portalHalfHeight = portal.height / 2;

    const playerLeft = player.x - playerSize;
    const playerRight = player.x + playerSize;
    const playerTop = player.y - playerSize;
    const playerBottom = player.y + playerSize;

    const portalLeft = portal.x - portalHalfWidth;
    const portalRight = portal.x + portalHalfWidth;
    const portalTop = portal.y - portalHalfHeight;
    const portalBottom = portal.y + portalHalfHeight;

    return (
      playerRight > portalLeft &&
      playerLeft < portalRight &&
      playerBottom > portalTop &&
      playerTop < portalBottom
    );
  }

  protected async handlePortalCollision(portal: any) {
    this.playPortalEnterSound();
    this.shutdown(this.scene.key);
    const playerData = this.registry.get("playerData");
    const password = this.registry.get("password");

    if (!playerData?.username || !password) {
      console.error("Missing credentials for room transition");
      this.scene.start("login");
      return;
    }

    await new Promise((resolve) => setTimeout(resolve, 100));
    const newRoom = await this.client.join(portal.targetRoom, {
      username: playerData.username,
      password: password,
      targetX: portal.targetX,
      targetY: portal.targetY,
    });

    this.registry.set("room", newRoom);
    this.game.events.emit("portal-collision");
    this.scene.start(portal.targetRoom);
  }

  protected setupPortalCollisions() {
    if (!this.room || !this.room.state.portals) return;
    this.room.state.portals.onAdd((portal) => {
      this.add.rectangle(
        portal.x,
        portal.y,
        portal.width,
        portal.height,
        0x00ffff,
        0.3
      );
      this.add
        .text(
          portal.x,
          portal.y - portal.height / 2 - 20,
          `To ${portal.targetRoom}`,
          {
            fontSize: "16px",
            color: "#ffffff",
          }
        )
        .setOrigin(0.5);
    });
  }

  protected handleServerReconciliation(serverPlayer: any) {
    if (!this.currentPlayer) return;
    const serverTick = serverPlayer.lastProcessedTick || 0;
    this.pendingInputs = this.pendingInputs.filter(
      (input) => input.tick > serverTick
    );
    const dx = Math.abs(this.currentPlayer.x - serverPlayer.x);
    const dy = Math.abs(this.currentPlayer.y - serverPlayer.y);

    if (
      dx > this.reconciliationThreshold ||
      dy > this.reconciliationThreshold
    ) {
      this.currentPlayer.x = serverPlayer.x;
      this.currentPlayer.y = serverPlayer.y;
      this.currentPlayer.setData("velocityX", serverPlayer.velocityX);
      this.currentPlayer.setData("velocityY", serverPlayer.velocityY);
      this.pendingInputs.forEach((input) => this.applyInput(input));
    }
    this.handlePlayerHealthChange(serverPlayer, this.currentPlayer);
  }

  protected updatePlayerAnimations() {
    if (!this.currentPlayer || !this.currentPlayer.scene) return;
    if (this.currentPlayer.getData("isAttacking")) {
      this.currentPlayer.play("character-attack", true);
    } else if (!this.currentPlayer.getData("canJump")) {
      this.currentPlayer.play("character-jump", true);
    } else if (this.inputPayload.left || this.inputPayload.right) {
      this.currentPlayer.play("character-run", true);
    } else {
      this.currentPlayer.play("character-idle", true);
    }
    if (this.inputPayload.left) this.currentPlayer.setFlipX(true);
    else if (this.inputPayload.right) this.currentPlayer.setFlipX(false);
  }

  protected applyInput(input: any) {
    if (!this.currentPlayer) return;
    const horizontalVelocity = 2;
    const gravity = 0.5;
    const jumpVelocity = -12;

    this.currentPlayer.setData("prevX", this.currentPlayer.x);
    this.currentPlayer.setData("prevY", this.currentPlayer.y);
    this.currentPlayer.setData("velocityX", 0);

    if (!this.currentPlayer.getData("isAttacking")) {
      if (input.left) {
        this.currentPlayer.x -= horizontalVelocity;
        this.currentPlayer.setData("velocityX", -horizontalVelocity);
        for (const obstacle of this.obstacles) {
          if (this.checkCollision(this.currentPlayer, obstacle)) {
            this.currentPlayer.x = this.currentPlayer.getData("prevX");
            this.currentPlayer.setData("velocityX", 0);
            break;
          }
        }
      } else if (input.right) {
        this.currentPlayer.x += horizontalVelocity;
        this.currentPlayer.setData("velocityX", horizontalVelocity);
        for (const obstacle of this.obstacles) {
          if (this.checkCollision(this.currentPlayer, obstacle)) {
            this.currentPlayer.x = this.currentPlayer.getData("prevX");
            this.currentPlayer.setData("velocityX", 0);
            break;
          }
        }
      }
      if (input.jump && this.currentPlayer.getData("canJump")) {
        this.currentPlayer.setData("velocityY", jumpVelocity);
        this.currentPlayer.setData("canJump", false);
        this.playJumpSound();
      }
    }

    let velocityY = this.currentPlayer.getData("velocityY") || 0;
    velocityY += gravity;
    this.currentPlayer.setData("velocityY", velocityY);
    this.currentPlayer.y += velocityY;

    let canJump = false;
    for (const obstacle of this.obstacles) {
      if (this.checkCollision(this.currentPlayer, obstacle)) {
        const playerBottom = this.currentPlayer.getData("prevY") + 16;
        const obstacleTop = obstacle.y - obstacle.height / 2;
        if (playerBottom <= obstacleTop) {
          this.currentPlayer.y = obstacleTop - 16;
          this.currentPlayer.setData("velocityY", 0);
          canJump = true;
        } else {
          this.currentPlayer.y = this.currentPlayer.getData("prevY");
          this.currentPlayer.setData("velocityY", 0);
        }
        break;
      }
    }
    this.currentPlayer.setData("canJump", canJump);
    this.updatePlayerAnimations();
  }

  protected updateOtherPlayersEffects() {
    if (!this.room || !this.room.state.spawnedPlayers) return;
    const currentUsername = this.registry.get("playerData").username;
    this.room.state.spawnedPlayers.forEach((player: any) => {
      if (player.username === currentUsername) return;
      const entity = this.playerEntities[player.username];
      if (!entity) return;
      if (player.isInvulnerable) {
        const flashRate = 100;
        const shouldBeVisible = Math.floor(this.time.now / flashRate) % 2 === 0;
        entity.setAlpha(shouldBeVisible ? 1 : 0.7);
        entity.setTint(shouldBeVisible ? 0xffffff : 0xff9999);
      } else {
        entity.setAlpha(1);
        entity.clearTint();
      }
    });
  }

  protected fixedTick(time: number, delta: number) {
    if (!this.currentPlayer || !this.room) return;
    this.currentTick++;

    if (!this.chatFocused) {
      this.inputPayload.left = this.aKey.isDown;
      this.inputPayload.right = this.dKey.isDown;
      this.inputPayload.up = this.wKey.isDown;
      this.inputPayload.down = this.sKey.isDown;
      this.inputPayload.jump = this.cursorKeys.space.isDown;
      this.inputPayload.loot = this.collectKey.isDown;
      this.inputPayload.attack = !!this.currentPlayer.getData("queuedAttack");
      if (this.inputPayload.attack) this.playSwordSlashSound();
    } else {
      this.inputPayload.left = false;
      this.inputPayload.right = false;
      this.inputPayload.up = false;
      this.inputPayload.down = false;
      this.inputPayload.jump = false;
      this.inputPayload.loot = false;
      this.inputPayload.attack = false;
    }

    this.inputPayload.tick = this.currentTick;
    const input = { ...this.inputPayload };
    this.applyInput(input);
    this.pendingInputs.push(input);

    if (this.room.connection.isOpen) {
      this.room.send(0, this.inputPayload);
      this.currentPlayer.setData("queuedAttack", false);
    }

    for (let username in this.playerEntities) {
      if (username === this.registry.get("playerData").username) continue;
      const entity = this.playerEntities[username];
      if (!entity || !entity.data?.values) continue;
      const { serverX, serverY, isAttacking, canJump, velocityX } =
        entity.data.values;
      if (serverX !== undefined && serverY !== undefined) {
        entity.x = Phaser.Math.Linear(entity.x, serverX, 0.4);
        entity.y = Phaser.Math.Linear(entity.y, serverY, 0.6);
        if (isAttacking) entity.play("character-attack", true);
        else if (!canJump) entity.play("character-jump", true);
        else if (velocityX !== 0) entity.play("character-run", true);
        else entity.play("character-idle", true);
        if (velocityX < 0) entity.setFlipX(true);
        else if (velocityX > 0) entity.setFlipX(false);
      }
    }

    Object.values(this.monsterEntities).forEach((entity) => {
      if (!entity || !entity.data?.values) return;
      const { serverX, serverY } = entity.data.values;
      if (serverX !== undefined && serverY !== undefined) {
        entity.x = Phaser.Math.Linear(entity.x, serverX, 0.4);
        entity.y = Phaser.Math.Linear(entity.y, serverY, 0.6);
      }
    });

    Object.entries(this.lootEntities).forEach(([index, entity]) => {
      if (!entity || !entity.data?.values) {
        delete this.lootEntities[index];
        return;
      }
      const { serverX, serverY } = entity.data.values;
      if (serverX !== undefined && serverY !== undefined) {
        entity.x = Phaser.Math.Linear(entity.x, serverX, 0.4);
        entity.y = Phaser.Math.Linear(entity.y, serverY, 0.6);
      }
    });

    if (this.portalCooldown > 0) this.portalCooldown--;
    if (
      this.currentPlayer &&
      this.room.state.portals &&
      this.portalCooldown <= 0 &&
      this.wKey.isDown
    ) {
      for (const portal of this.room.state.portals) {
        if (this.checkPortalCollision(this.currentPlayer, portal)) {
          this.portalCooldown = 60;
          this.handlePortalCollision(portal);
          break;
        }
      }
    }

    if (this.currentPlayer && this.registry.get("playerData")?.username) {
      const username = this.registry.get("playerData").username;
      if (this.playerNameTags[username]) {
        this.playerNameTags[username].setPosition(
          this.currentPlayer.x,
          this.currentPlayer.y - 30
        );
      }
    }
    this.preventPlayerStuck();
  }

  protected shutdown(scenekey: string) {
    this.obstacles.forEach((entity) => entity.destroy());
    this.obstacles = [];
    Object.values(this.playerNameTags).forEach((nameTag) => nameTag?.destroy());
    this.playerNameTags = {};
    Object.values(this.monsterHealthBars).forEach((healthBar) =>
      healthBar?.destroy()
    );
    this.monsterHealthBars = {};
    Object.values(this.monsterNameTags).forEach((nameTag) =>
      nameTag?.destroy()
    );
    this.monsterNameTags = {};
    Object.values(this.monsterEntities).forEach((entity) => entity?.destroy());
    this.monsterEntities = {};
    Object.values(this.lootEntities).forEach((entity) => entity?.destroy());
    this.lootEntities = {};

    // Stop background music when leaving the scene
    this.stopBackgroundMusic();

    this.cache.tilemap.remove(scenekey);
    this.room.leave();
    this.scene.stop(scenekey);
  }

  protected createMonsterHealthBar(monster: any, id: string) {
    if (this.monsterHealthBars[id]) this.monsterHealthBars[id].destroy();
    const container = this.add.container(monster.x, monster.y - 15);
    container.setDepth(5);

    const background = this.add.rectangle(0, 0, 30, 3, 0x000000);
    background.setOrigin(0.5, 0.5);
    const healthBar = this.add.rectangle(0, 0, 30, 3, 0x00ff00);
    healthBar.setOrigin(0.5, 0.5);

    container.add([background, healthBar]);
    container.setData("background", background);
    container.setData("healthBar", healthBar);
    container.setData("maxHealth", monster.maxHealth);
    container.setData("currentHealth", monster.currentHealth);
    this.monsterHealthBars[id] = container;

    if (!this.monsterNameTags[id]) {
      const nameTag = this.add.text(monster.x, monster.y - 22, monster.name, {
        fontSize: "10px",
        color: "#ffffff",
        stroke: "#000000",
        strokeThickness: 2,
        fontFamily: "Arial",
      });
      nameTag.setOrigin(0.5, 0.5);
      nameTag.setDepth(5);
      this.monsterNameTags[id] = nameTag;
    }

    return container;
  }

  protected updateMonsterHealthBar(monster: any, id: string) {
    const container = this.monsterHealthBars[id];
    if (!container) return;

    const background = container.getData("background");
    const healthBar = container.getData("healthBar");
    const maxHealth = container.getData("maxHealth");
    const currentHealth = monster.currentHealth;

    const healthPercent = Math.max(0, Math.min(1, currentHealth / maxHealth));
    healthBar.width = background.width * healthPercent;
    let color = 0x00ff00;
    if (healthPercent < 0.6) color = 0xffff00;
    if (healthPercent < 0.3) color = 0xff0000;
    healthBar.fillColor = color;

    const entity = this.monsterEntities[id];
    if (entity) {
      container.x = entity.x;
      container.y = entity.y - 15;
      const nameTag = this.monsterNameTags[id];
      if (nameTag) {
        nameTag.x = entity.x;
        nameTag.y = entity.y - 22;
      }
    }
    container.setData("currentHealth", currentHealth);
  }

  protected removeMonsterHealthBar(id: string) {
    const container = this.monsterHealthBars[id];
    if (container) {
      container.destroy();
      delete this.monsterHealthBars[id];
    }
    const nameTag = this.monsterNameTags[id];
    if (nameTag) {
      nameTag.destroy();
      delete this.monsterNameTags[id];
    }
  }

  protected showDamageNumber(x: number, y: number, damage: number) {
    this.playEnemyHitSound();
    if (isNaN(damage) || damage <= 0) return;

    // Add custom font if not already added
    if (!document.querySelector('link[href*="MedievalSharp"]')) {
      const fontLink = document.createElement("link");
      fontLink.rel = "stylesheet";
      fontLink.href =
        "https://fonts.googleapis.com/css2?family=MedievalSharp&display=swap";
      document.head.appendChild(fontLink);
    }

    const offsetX = Phaser.Math.Between(-10, 10);
    const offsetY = Phaser.Math.Between(-5, 0);

    // More vibrant colors for damage numbers
    let color =
      damage > 60
        ? "#FFD700" // Gold for high damage
        : damage > 40
        ? "#FF8C00" // Dark orange for medium-high damage
        : damage > 20
        ? "#FF4500" // Orange-red for medium damage
        : "#FF6347"; // Tomato for low damage

    // Create back text for the outer black outline
    const backText = this.add.text(
      x + offsetX,
      y - 15 + offsetY,
      `${Math.round(damage)}`,
      {
        fontSize: "18px",
        fontFamily: "'MedievalSharp', cursive",
        color: "rgba(0,0,0,0)", // Transparent fill
        stroke: "#000000", // Black stroke
        strokeThickness: 4, // Thicker for outer outline
        fontStyle: "bold",
      }
    );
    backText.setOrigin(0.5, 0.5);
    backText.setDepth(1000);
    backText.setResolution(3); // Higher resolution for sharper text

    // Create front text for the inner white outline and colored fill
    const frontText = this.add.text(
      x + offsetX,
      y - 15 + offsetY,
      `${Math.round(damage)}`,
      {
        fontSize: "18px",
        fontFamily: "'MedievalSharp', cursive",
        color: color, // The damage color
        stroke: "#ffffff", // White stroke
        strokeThickness: 2, // Thinner for inner outline
        fontStyle: "bold",
      }
    );
    frontText.setOrigin(0.5, 0.5);
    frontText.setDepth(1001); // Ensure frontText is above backText
    frontText.setResolution(3); // Higher resolution for sharper text

    // Animate both texts together with a slightly more dynamic animation
    this.tweens.add({
      targets: [backText, frontText],
      y: "-=50", // Move up 50 pixels relative to starting position
      alpha: { from: 1, to: 0.7 },
      scale: { from: 1, to: 1.5 },
      duration: 800,
      ease: "Sine.easeOut",
      onComplete: () => {
        backText.destroy();
        frontText.destroy();
      },
    });
  }

  protected preventPlayerStuck() {
    if (!this.currentPlayer) return;
    for (const obstacle of this.obstacles) {
      if (this.checkCollision(this.currentPlayer, obstacle)) {
        if (obstacle.getData("isOneWayPlatform")) continue;
        const overlapX = Math.min(
          this.currentPlayer.x +
            this.currentPlayer.width / 2 -
            (obstacle.x - obstacle.width / 2),
          obstacle.x +
            obstacle.width / 2 -
            (this.currentPlayer.x - this.currentPlayer.width / 2)
        );
        const overlapY = Math.min(
          this.currentPlayer.y +
            this.currentPlayer.height / 2 -
            (obstacle.y - obstacle.height / 2),
          obstacle.y +
            obstacle.height / 2 -
            (this.currentPlayer.y - this.currentPlayer.height / 2)
        );
        if (overlapX < overlapY) {
          this.currentPlayer.x +=
            this.currentPlayer.x < obstacle.x ? -overlapX : overlapX;
        } else {
          this.currentPlayer.y +=
            this.currentPlayer.y < obstacle.y ? -overlapY : overlapY;
        }
      }
    }
  }

  protected loadSounds() {
    this.load.audio("collectcoin", "assets/Sounds/collectcoin.mp3");
    this.load.audio("swordslash", "assets/Sounds/swordslash.mp3");
    this.load.audio("enemyhit", "assets/Sounds/enemyhit.mp3");
    this.load.audio("portalenter", "assets/Sounds/portalenter.mp3");
    this.load.audio("jump", "assets/Sounds/jump.mp3");
  }

  protected initSounds() {
    this.sounds.collectcoin = this.sound.add("collectcoin");
    this.sounds.swordslash = this.sound.add("swordslash");
    this.sounds.enemyhit = this.sound.add("enemyhit");
    this.sounds.portalenter = this.sound.add("portalenter");
    this.sounds.jump = this.sound.add("jump");
  }

  protected playCollectCoinSound() {
    this.sounds.collectcoin?.play({ volume: 0.25 });
  }

  protected playSwordSlashSound() {
    this.sounds.swordslash?.play();
  }

  protected playEnemyHitSound() {
    this.sounds.enemyhit?.play();
  }

  protected playPortalEnterSound() {
    this.sounds.portalenter?.play();
  }

  protected playJumpSound() {
    this.sounds.jump?.play();
  }

  /** Handles player level up effects */
  protected handlePlayerLevelUp(username: string, newLevel: number) {
    const entity = this.playerEntities[username];
    if (!entity) return;

    // Play level up animation
    const levelUpAnimation = this.add.sprite(
      entity.x,
      entity.y - 50, // Moved up from -40 to -50
      "level-up-effect"
    );
    levelUpAnimation.setDepth(20);
    levelUpAnimation.setScale(1.5);
    levelUpAnimation.play("level-up-animation");

    // Add custom font if not already added
    if (!document.querySelector('link[href*="MedievalSharp"]')) {
      const fontLink = document.createElement("link");
      fontLink.rel = "stylesheet";
      fontLink.href =
        "https://fonts.googleapis.com/css2?family=MedievalSharp&display=swap";
      document.head.appendChild(fontLink);
    }

    // Create back text for the outer black outline
    const backText = this.add.text(entity.x, entity.y - 100, `LEVEL UP`, {
      fontSize: "22px",
      fontFamily: "'MedievalSharp', cursive",
      color: "rgba(0,0,0,0)", // Transparent fill
      stroke: "#000000", // Black stroke
      strokeThickness: 4, // Thicker for outer outline
      fontStyle: "bold",
    });
    backText.setOrigin(0.5, 0.5);
    backText.setDepth(1000);
    backText.setResolution(3); // Higher resolution for sharper text

    // Create front text for the inner white outline and colored fill
    const frontText = this.add.text(entity.x, entity.y - 100, `LEVEL UP`, {
      fontSize: "22px",
      fontFamily: "'MedievalSharp', cursive",
      color: "#FFD700", // Gold color for level up
      stroke: "#ffffff", // White stroke
      strokeThickness: 2, // Thinner for inner outline
      fontStyle: "bold",
    });
    frontText.setOrigin(0.5, 0.5);
    frontText.setDepth(1001); // Ensure frontText is above backText
    frontText.setResolution(3); // Higher resolution for sharper text

    // Animate both texts together with a slightly more dynamic animation
    this.tweens.add({
      targets: [backText, frontText],
      y: "-=50", // Move up 50 pixels relative to starting position
      alpha: { from: 1, to: 0.7 },
      scale: { from: 1, to: 1.5 },
      duration: 1200,
      ease: "Sine.easeOut",
      onComplete: () => {
        backText.destroy();
        frontText.destroy();
      },
    });

    // Remove the animation sprite when animation completes
    levelUpAnimation.once("animationcomplete", () => {
      levelUpAnimation.destroy();
    });
  }

  protected playBackgroundMusic(key: string) {
    // Stop any currently playing background music
    if (this.sounds.backgroundMusic && this.sounds.backgroundMusic.isPlaying) {
      this.sounds.backgroundMusic.stop();
    }

    // Play the new background music with looping enabled
    this.sounds.backgroundMusic = this.sound.add(key);
    this.sounds.backgroundMusic.play({
      loop: true,
      volume: 0.5,
    });
  }

  protected stopBackgroundMusic() {
    if (this.sounds.backgroundMusic && this.sounds.backgroundMusic.isPlaying) {
      this.sounds.backgroundMusic.stop();
    }
  }
}
